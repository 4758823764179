import axios from "axios";

export const checkUser = async (props, _isMounted) => {
  try {
    const response = await axios.get("/api/users/currentuser");

    if (_isMounted) {
      if (response.data.current_user === null) {
        // Save only the relative path (no domain) to localStorage
        localStorage.setItem(
          "redirectAfterLogin",
          props.location.pathname + props.location.search
        );

        // Redirect to sign-in page
        props.history.push("/signin");
      } else {
        console.log(JSON.stringify(response.data));
      }
    }
  } catch (error) {
    if (_isMounted) {
      console.log(error.response?.data?.errors[0]?.message || "Error occurred");
    }
  }
};
