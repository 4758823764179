import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
/* import jsPDF from "jspdf";
import html2canvas from "html2canvas"; */
import "./BlogDetails.css"; // Import the CSS file

export default class InsctBlogView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogDetails: {},
    };
  }

  componentDidMount() {
    const blogId = this.props.blogId || this.props.match.params.blogId;

    if (blogId) {
      this.getBlogById(blogId);
    } else {
      console.error("No blog ID found");
    }

    // Add event listeners for image toggle functionality
    this.addImageClickListeners();
  }

  componentDidUpdate(prevProps, prevState) {
    // Re-add listeners if blog content changes
    if (
      prevState.blogDetails.blog_content !== this.state.blogDetails.blog_content
    ) {
      this.addImageClickListeners();
    }
  }

  componentWillUnmount() {
    // Remove event listeners when component is unmounted
    this.removeImageClickListeners();
  }

  addImageClickListeners = () => {
    // Select all images inside blog-content
    const images = document.querySelectorAll(".blog-content img");

    images.forEach((img) => {
      img.addEventListener("click", this.toggleImageSize);
    });
  };

  removeImageClickListeners = () => {
    // Remove event listeners when the component unmounts
    const images = document.querySelectorAll(".blog-content img");

    images.forEach((img) => {
      img.removeEventListener("click", this.toggleImageSize);
    });
  };

  toggleImageSize = (e) => {
    const img = e.target;
    img.classList.toggle("enlarged");
  };

  async getBlogById(blog_Id) {
    try {
      const response = await axios.get(`/api/blogs/v1/${blog_Id}`);
      this.setState({ blogDetails: response.data });
    } catch (error) {
      console.error("Error fetching blog details:", error);

      // Check for 401 error or if the response indicates a subscription is required
      if (error.response && error.response.status === 401) {
        const { require_subscription, blog_id, message } = error.response.data;

        if (require_subscription === "yes") {
          console.log(message); // Log the message for debugging

          // Redirect to subscription page with the blogId
          this.props.history.push("/subscription?blogId=" + blog_id);
        } else {
          console.error(
            "Unauthorized access. No subscription required, but received 401."
          );
        }
      } else {
        console.error("Unexpected error occurred:", error);
      }
    }
  }

  /*  generatePDF = () => {
    console.log("just inside");
    const { blogDetails } = this.state;
    const input = document.getElementById("pdfContainer");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add watermark on the first page
      this.addWatermark(pdf);

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Add watermark on subsequent pages
        this.addWatermark(pdf);
      }

      // Generate the PDF with the blog title in the filename
      const fileName = `examarc_${blogDetails.blog_title.replace(
        / /g,
        "_"
      )}.pdf`;
      pdf.save(fileName);
    });
  };

  addWatermark = (pdf) => {
    const watermarkText = "www.examarc.com";
    pdf.setFontSize(25);
    pdf.setTextColor(200);
    pdf.text(
      watermarkText,
      pdf.internal.pageSize.getWidth() / 2,
      pdf.internal.pageSize.getHeight() / 2,
      {
        align: "justify",
        angle: 0,
      }
    );
  }; */

  render() {
    const { blogDetails } = this.state;

    if (!blogDetails || Object.keys(blogDetails).length === 0) {
      return <div>Loading...</div>;
    }

    const formattedCreatedDate = moment(blogDetails.creation_date).format(
      "MMMM Do YYYY, h:mm A"
    );
    const formattedUpdatedDate = moment(blogDetails.modification_date).format(
      "MMMM Do YYYY, h:mm A"
    );

    return (
      <div className="container">
        {/*  <div className="cardsss col-lg-10 col-md-10 col-sm-12 col-xs-12">
          <button onClick={this.generatePDF} className="download-button">
            Download as PDF
          </button>
        </div> */}
        <div id="pdfContainer" className="row">
          <div className="cardsss col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <h3>
              {blogDetails.blog_title}
              {blogDetails.blog_premium_title &&
                `: ${blogDetails.blog_premium_title}`}
            </h3>
            <div className="date-and-button">
              <div className="dates">
                <p>
                  <strong>Created on:</strong> {formattedCreatedDate}
                </p>
                <p>
                  <strong>Last updated:</strong> {formattedUpdatedDate}
                </p>
              </div>
            </div>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blogDetails.blog_content }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
