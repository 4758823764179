// ToastProvider.js
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastProvider = ({ children }) => {
  return (
    <>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        position="top-right"
        closeOnClick
        pauseOnHover
        draggable
        style={{
          maxWidth: "400px",
          padding: "10px",
          fontSize: "14px",
        }}
      />
      {children}
    </>
  );
};

export const showSuccessToast = (message) => toast.success(message);
export const showErrorToast = (message) => toast.error(message);
export const showInfoToast = (message) => toast.info(message);
export const showWarningToast = (message) => toast.warn(message);
