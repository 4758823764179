import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import "./Style.css";

import bcrypt from "bcryptjs";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationDataInstitution: {
        user_type: "institution",
      },
      registrationData: {
        user_type: "instructor",
      },
      errors: {},
      passwordValidation: {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
      },
      passwordValidationInstitution: {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
      },
      emailValid: false, // Track email validation
      showPassword: false, // Track password visibility
      emailValidInstitution: false, // Track email validation
      showPasswordInstitution: false, // Track password visibility
      Redirect: false,
      isIndividualTutor: true, // Set to true to make "Individual Tutor" tab active by default
      showPasswordCriteria: false, // Add this state variable
      showPasswordInstitutionCriteria: false, // Add this state variable
    };
  }

  loginChange(field, e) {
    let registrationData = this.state.registrationData;
    registrationData[field] = e.target.value;
    this.setState({ registrationData });

    // Trigger password validation as the user types
    if (field === "password") {
      this.validatePassword(e.target.value);
    }

    // Trigger email validation as the user types
    if (field === "email") {
      this.validateEmail(e.target.value);
    }
  }

  // Password validation based on various criteria
  validatePassword(password) {
    let passwordValidation = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
    };
    this.setState({ passwordValidation });
  }

  // Email validation
  validateEmail(email) {
    const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      email
    );
    this.setState({ emailValid });
  }

  loginChangeInstitution(field, e) {
    let registrationDataInstitution = this.state.registrationDataInstitution;
    registrationDataInstitution[field] = e.target.value;
    this.setState({ registrationDataInstitution });
    // Trigger password validation as the user types
    if (field === "password") {
      this.validatePasswordInstitution(e.target.value);
    }

    // Trigger email validation as the user types
    if (field === "institutionemail") {
      this.validateEmailInstitution(e.target.value);
    }
  }

  // Password validation based on various criteria
  validatePasswordInstitution(password) {
    let passwordValidationInstitution = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
    };
    this.setState({ passwordValidationInstitution });
  }

  // Email validation
  validateEmailInstitution(email) {
    const emailValidInstitution =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    this.setState({ emailValidInstitution });
  }

  // Add additional validation rules for each field
  handleValidation() {
    let registrationData = this.state.registrationData;
    let errors = {};
    let formIsValid = true;

    // First name
    if (!registrationData["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    } else if (registrationData["first_name"].length < 2) {
      formIsValid = false;
      errors["first_name"] = "Must be at least 2 characters in length.";
    }

    // Last name
    if (!registrationData["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    } else if (registrationData["last_name"].length < 2) {
      formIsValid = false;
      errors["last_name"] = "Must be at least 2 characters in length.";
    }

    // Email
    if (!registrationData["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        registrationData["email"]
      )
    ) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    } else {
      // Show the email validation success message
      this.setState({ emailValidationSuccess: true });
    }

    // Validate password (already handled via real-time validation)
    const { length, uppercase, lowercase, number } =
      this.state.passwordValidation;
    if (!length || !uppercase || !lowercase || !number) {
      formIsValid = false;
      errors["password"] = "Password must meet the criteria.";
    } else {
      registrationData["password"] = bcrypt.hashSync(
        registrationData["password"],
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ); // hash created previously created upon sign up
    }

    // Handle validation errors more gracefully
    if (!formIsValid) {
      // Display the list of validation errors to the user
      // Prevent the user from submitting the form until the errors have been fixed
    }

    this.setState({ errors: errors });

    return formIsValid;
  }

  handleValidationForInstitution() {
    let registrationDataInstitution = this.state.registrationDataInstitution;
    let errors = {};
    let formIsValid = true;

    // First name
    if (!registrationDataInstitution["name"]) {
      formIsValid = false;
      errors["name"] = "Institution name cannot be empty.";
    } else if (registrationDataInstitution["name"].length < 2) {
      formIsValid = false;
      errors["name"] = "Must be at least 2 characters in length.";
    }

    // Email
    if (!registrationDataInstitution["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        registrationDataInstitution["email"]
      )
    ) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    } else {
      // Show the email validation success message
      this.setState({ emailValidationSuccess: true });
    }

    // Validate password (already handled via real-time validation)
    const { length, uppercase, lowercase, number } =
      this.state.passwordValidationInstitution;
    if (!length || !uppercase || !lowercase || !number) {
      formIsValid = false;
      errors["password"] = "Password must meet the criteria.";
    } else {
      registrationDataInstitution["password"] = bcrypt.hashSync(
        registrationDataInstitution["password"],
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ); // hash created previously created upon sign up
    }

    // Handle validation errors more gracefully
    if (!formIsValid) {
      // Display the list of validation errors to the user
      // Prevent the user from submitting the form until the errors have been fixed
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onLoginFormSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/users/signup",
        data: this.state.registrationData,
      })
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem("userData", JSON.stringify(response.data));
            this.setState({ Redirect: true });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.errors[0].message);
        });
    }
  };

  onLoginFormSubmitForInstitution = (e) => {
    console.log(this.state.registrationDataInstitution);
    e.preventDefault();
    if (this.handleValidationForInstitution()) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/users/signup",
        data: this.state.registrationDataInstitution,
      })
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem("userData", JSON.stringify(response.data));
            this.setState({ Redirect: true });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.errors[0].message);
        });
    }
  };

  clearError = (field) => {
    let errors = { ...this.state.errors };
    errors[field] = "";
    this.setState({ errors });
  };

  render() {
    const {
      passwordValidation,
      emailValid,
      showPassword,
      passwordValidationInstitution,
      emailValidInstitution,
      showPasswordInstitution,
    } = this.state;

    if (this.state.Redirect === true) {
      return <Redirect to="/instructor-details" />;
    }
    return (
      <div
        className="regi"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "10px" }}
      >
        <div className="container">
          <h5 className="signin mt-4">Sign Up</h5>
          <br />
          <ul className="nav nav-tabs">
            {/* Individual Tutor tab */}
            <li className="nav-item col-7 text-center">
              <button
                className={`nav-link ${
                  this.state.isIndividualTutor ? "active" : ""
                }`}
                onClick={() => this.setState({ isIndividualTutor: true })}
              >
                Individual Tutor
              </button>
            </li>

            {/* Institution tab */}
            <li className="nav-item col-5 text-center">
              <button
                className={`nav-link ${
                  !this.state.isIndividualTutor ? "active" : ""
                }`}
                onClick={() => this.setState({ isIndividualTutor: false })}
              >
                Institution
              </button>
            </li>
          </ul>

          <form method="post" onSubmit={this.onLoginFormSubmit}>
            {/* Common fields for both options */}

            {/* Fields specific to Individual Tutor */}
            {this.state.isIndividualTutor && (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    placeholder="Enter Your First Name*"
                    onChange={(e) => {
                      this.loginChange("first_name", e);
                      this.handleValidation("first_name");
                    }}
                    onFocus={() => this.clearError("first_name")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationData["first_name"] || ""
                    }
                  />

                  <span style={{ color: "red" }}>
                    {this.state.errors["first_name"]}
                  </span>
                </div>
                {/* Last Name field */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.loginChange("last_name", e);
                      this.handleValidation("last_name");
                    }}
                    onFocus={() => this.clearError("last_name")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationData["last_name"] || ""
                    }
                    name="last_name"
                    placeholder="Enter Your Last Name*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["last_name"]}
                  </span>
                </div>

                {/* Email field */}
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    name="email"
                    onChange={this.loginChange.bind(this, "email")}
                    onFocus={() => this.clearError("email")} // Add this line to clear error on focus
                    defaultValue={this.state.registrationData["email"] || ""}
                    placeholder="Enter Your Email*"
                  />
                  <span style={{ color: emailValid ? "green" : "red" }}>
                    {emailValid
                      ? "✔️ Email validation success"
                      : this.state.errors["email"]}
                  </span>
                </div>

                <div className="form-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    onChange={this.loginChange.bind(this, "password")}
                    defaultValue={this.state.registrationData["password"] || ""}
                    name="password"
                    placeholder="Create Password*"
                    onFocus={() => {
                      this.clearError("password");
                      this.setState({ showPasswordCriteria: true }); // Show criteria on focus
                    }}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["password"]}
                  </span>
                  {/* Conditionally render the password requirements based on showPasswordCriteria */}
                  {this.state.showPasswordCriteria && (
                    <div className="password-requirements">
                      <p
                        style={{
                          color: passwordValidation.length ? "green" : "red",
                        }}
                      >
                        {passwordValidation.length ? "✔️" : "❌"} At least 8
                        characters
                      </p>
                      <p
                        style={{
                          color: passwordValidation.uppercase ? "green" : "red",
                        }}
                      >
                        {passwordValidation.uppercase ? "✔️" : "❌"} At least 1
                        uppercase letter
                      </p>
                      <p
                        style={{
                          color: passwordValidation.lowercase ? "green" : "red",
                        }}
                      >
                        {passwordValidation.lowercase ? "✔️" : "❌"} At least 1
                        lowercase letter
                      </p>
                      <p
                        style={{
                          color: passwordValidation.number ? "green" : "red",
                        }}
                      >
                        {passwordValidation.number ? "✔️" : "❌"} At least 1
                        number
                      </p>
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn--wide btn-sm bg-green"
                    name="create"
                  >
                    Sign up
                  </button>
                  <div className="mt-2">
                    <button
                      style={{
                        textDecoration: "none",
                        border: "none",
                        background: "none",
                        color: "#007bff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // Add your navigation logic here
                        // For example, use react-router-dom to navigate to the sign-in page
                        this.props.history.push("/signin");
                      }}
                    >
                      Already have an account? Sign in
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
          <form method="post" onSubmit={this.onLoginFormSubmitForInstitution}>
            {/* Fields specific to Institution */}
            {!this.state.isIndividualTutor && (
              <>
                {/* Institution Name */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.loginChangeInstitution("name", e);
                      this.handleValidationForInstitution("name");
                    }}
                    onFocus={() => this.clearError("name")}
                    defaultValue={
                      this.state.registrationDataInstitution["name"] || ""
                    }
                    name="name"
                    placeholder="Enter Your Institution Name*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["name"]}
                  </span>
                </div>

                {/* Institution Email */}
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="institutionemail"
                    onChange={this.loginChangeInstitution.bind(
                      this,
                      "institutionemail"
                    )}
                    onFocus={() => this.clearError("institutionemail")}
                    defaultValue={
                      this.state.registrationDataInstitution[
                        "institutionemail"
                      ] || ""
                    }
                    placeholder="Enter Institution Email*"
                  />
                  <span
                    style={{ color: emailValidInstitution ? "green" : "red" }}
                  >
                    {emailValidInstitution
                      ? "✔️ Email validation success"
                      : this.state.errors["institutionemail"]}
                  </span>
                </div>

                {/* Institution Password */}
                <div className="form-group">
                  <input
                    type={showPasswordInstitution ? "text" : "password"}
                    className="form-control"
                    onChange={this.loginChangeInstitution.bind(
                      this,
                      "password"
                    )}
                    onFocus={() => {
                      this.clearError("inpassword");
                      this.setState({ showPasswordCriteriaInstitution: true });
                    }}
                    defaultValue={
                      this.state.registrationDataInstitution["password"] || ""
                    }
                    name="inpassword"
                    placeholder="Create Password*"
                  />
                  <span style={{ color: "darkblue" }}>
                    {this.state.errors["inpassword"]}
                  </span>

                  {this.state.showPasswordCriteriaInstitution && (
                    <div className="password-requirements">
                      <p
                        style={{
                          color: passwordValidationInstitution.length
                            ? "green"
                            : "red",
                        }}
                      >
                        {passwordValidationInstitution.length ? "✔️" : "❌"} At
                        least 8 characters
                      </p>
                      <p
                        style={{
                          color: passwordValidationInstitution.uppercase
                            ? "green"
                            : "red",
                        }}
                      >
                        {passwordValidationInstitution.uppercase ? "✔️" : "❌"}{" "}
                        At least 1 uppercase letter
                      </p>
                      <p
                        style={{
                          color: passwordValidationInstitution.lowercase
                            ? "green"
                            : "red",
                        }}
                      >
                        {passwordValidationInstitution.lowercase ? "✔️" : "❌"}{" "}
                        At least 1 lowercase letter
                      </p>
                      <p
                        style={{
                          color: passwordValidationInstitution.number
                            ? "green"
                            : "red",
                        }}
                      >
                        {passwordValidationInstitution.number ? "✔️" : "❌"} At
                        least 1 number
                      </p>
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn--wide btn-sm bg-green"
                    name="create"
                  >
                    Sign up
                  </button>
                  <div className="mt-2">
                    <button
                      style={{
                        textDecoration: "none",
                        border: "none",
                        background: "none",
                        color: "#007bff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.props.history.push("/signin");
                      }}
                    >
                      Already have an account? Sign in
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>{" "}
      </div>
    );
  }
}

export default withRouter(SignUp);
