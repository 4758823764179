import axios from "axios";
import React, { Component } from "react";
import Loader from "react-loader-spinner";

import Accordion from "react-bootstrap/Accordion"; // Assuming you're using React-Bootstrap
import { Card, Button } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import Sidebar from "../../Sidebar"; // Ensure Sidebar is imported
import "./Enrollments.css";

class EnrollmentPosts extends Component {
  _isMounted = false; // Class property to track mounted state
  source = axios.CancelToken.source(); // Create a cancel token source

  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      blogData: {}, // Use blogData instead of blogs to be consistent
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getDomains();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.source.cancel("Component unmounted");
  }

  // Fetch domains
  async getDomains() {
    try {
      const response = await axios.get("/api/domains?status=active", {
        cancelToken: this.source.token,
      });

      if (this._isMounted) {
        const domains = response.data.domains; // Assuming API returns an array of domains
        console.log("Domains:", domains); // Debugging log

        // Fetch blogs for each domain
        const blogData = await this.fetchBlogsForDomains(domains);

        this.setState({ domains, blogData, loading: false }); // Ensure you are setting blogData, not blogs
        console.log("Blogs:", blogData); // Debugging log
      }
    } catch (error) {
      if (this._isMounted) {
        console.error("Error fetching domains:", error);
      }
    }
  }

  // Fetch blogs for each domain
  async fetchBlogsForDomains(domains) {
    const blogData = {}; // Ensure this is the correct format

    await Promise.all(
      domains.map(async (domain) => {
        try {
          const blogResponse = await axios.get(
            `/api/blogs/v1?domain=${domain.domain_id}&status=active`
          );
          const blogs = blogResponse.data.blogs;

          console.log(`Blogs for domain ${domain.domain_name}:`, blogs); // Debugging log

          // Group blogs by category
          blogs.forEach((blog) => {
            const domainName = blog.domain_name || "Unknown Domain"; // Fallback value if domain_name is undefined
            const categoryName = blog.catagory_name || "Unknown Category"; // Fallback value if category is undefined

            // Initialize domain group if it doesn't exist
            if (!blogData[domainName]) {
              blogData[domainName] = {};
            }

            // Initialize category group within the domain if it doesn't exist
            if (!blogData[domainName][categoryName]) {
              blogData[domainName][categoryName] = [];
            }

            // Add the blog to the correct domain and category
            blogData[domainName][categoryName].push(blog);
          });
        } catch (error) {
          console.error(
            "Error fetching blogs for domain:",
            domain.domain_name,
            error
          );
        }
      })
    );
    return blogData;
  }

  getBlogPage(blog_id) {
    // Open the blog page in a new tab
    // window.open(`/blog/${blog_id}`, "_blank");
    this.props.history.push("/blog/" + blog_id);
  }

  render() {
    const { loading, domains, blogData } = this.state;

    if (loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else if (!domains.length) {
      return <p>No domains found</p>;
    } else {
      return (
        <div className="container">
          <br />
          <div className="row">
            <Sidebar />
            <div className="container col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <Fade bottom duration={2000} distance="40px">
                <Accordion defaultActiveKey="0">
                  {/* Loop through blogData domains */}
                  {Object.keys(blogData).map((domainName, domainIdx) => {
                    const domainBlogs = blogData[domainName] || {}; // Ensure blogData for domain is defined

                    // Loop through each category in the domain
                    return Object.keys(domainBlogs).map(
                      (categoryName, categoryIdx) => {
                        const categoryBlogs = domainBlogs[categoryName].filter(
                          (blog) => blog.blog_status === "active"
                        );

                        // Only render the category if it has active blogs
                        if (categoryBlogs.length === 0) return null;

                        return (
                          <Card key={`${domainIdx}-${categoryIdx}`}>
                            <Card.Header
                              style={{
                                backgroundColor: "#212529",
                                padding: "8px 10px",
                              }}
                            >
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={`${domainIdx}-${categoryIdx}`}
                                style={{
                                  fontSize: "1rem",
                                  color: "#007bff",
                                  textDecoration: "none",
                                  padding: "0",
                                  textAlign: "left", // Align the text to the left
                                  width: "100%", // Make sure it spans the full width
                                }}
                              >
                                {/* Domain name and Category name as a single collapsible button */}
                                <p className="repo-name">{`${domainName} : ${categoryName}`}</p>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse
                              eventKey={`${domainIdx}-${categoryIdx}`}
                            >
                              <Card.Body>
                                {/* Display the list of blogs in this category */}
                                {categoryBlogs.map((blog, blogIdx) => (
                                  <div key={blogIdx} className="blog-item">
                                    <div
                                      onClick={() =>
                                        this.getBlogPage(blog.blog_id)
                                      }
                                    >
                                      {/* Blog Title */}
                                      <div className="repo-name-div">
                                        <object
                                          data="/images/test-icon.svg"
                                          width="25"
                                          height="25"
                                        ></object>
                                        <p className="repo-name">
                                          {blog.blog_title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      }
                    );
                  })}
                </Accordion>
              </Fade>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default EnrollmentPosts;
