import React, { Component } from "react";
import axios from "axios";
import "./InstructorExams.css";
import { Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
import AppConstants from "../../../Constants/AppConstants";
import Sidebar from "../../Sidebar";
import { Link } from "react-router-dom";

export default class InstructorBlogPost extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      showHide: false,
      blogResponse: [],
      blog_id: "",
      pagination: {
        pageNo: 1,
        count: 10,
        search: "",
        status: "",
        items: "",
        totalPage: 5,
        catagory: "",
        domain: "",
      },
      blogDetails: {},
      examDomainDetails: {},
      examCategoryDetails: {},
      questionsList: [],
      answerSet: [],
      tempList: [],
      creating: false,
      active: false,
      disabled: false,
      approved: false,
      waiting: false,
      competitive: false,
      public: false,
      mcq: false,
      domainDataList: [],
      categoryDataList: [],
      errors: {},
      redirect: false,
      loadig: true,
      newMessage: {},
      messagesHistory: [],
      chatExam: "",
      showMessage: [],
      newMessageflag: false,
      dataMessage: {},
      ImgUrl: AppConstants.IMG_URL,
    };
  }

  handleModalShowHide(exam_id) {
    this.setState({ exam_id });
    if (exam_id !== 0) {
      this.getAssistantsList(exam_id);
    } else {
      this.setState({ showHide: false });
    }
  }

  getAssistantsList(examId) {
    let assistants = [];
    this.setState({ assistants });
    if (examId) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore/" + examId + "/assistants",
      })
        .then((response) => {
          let assistants = [];
          let assistantsresp = response.data.assistants;
          if (assistantsresp && assistantsresp.length > 0) {
            for (let i = 0; i < assistantsresp.length; i++) {
              if (assistantsresp[i].validity !== "disabled") {
                assistants.push(assistantsresp[i]);
              }
            }
            this.setState({ assistants });
          }
          this.setState({ showHide: !this.state.showHide });
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkUser();
    this.getBlogList();
    let user = JSON.parse(sessionStorage.getItem("userData"));
    this.setState({ userData: user });
    let name = user.first_name;
    this.setState({ name: name });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          swal("Please login first");
          this.props.history.push("/signin");
        } else {
          console.log("logged in");
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log(error.response.data.errors[0].message);
        }
      });
  }

  async getBlogList() {
    let url = "/api/blogs/v1";
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));
    if (instructorData.user_id) {
      url = url + "?blog_creator=" + instructorData.user_id;
    }
    if (this.state.pagination.search !== "") {
      url = url + "&search_key=" + this.state.pagination.search;
    }
    if (this.state.pagination.pageNo !== "") {
      url = url + "&page_no=" + this.state.pagination.pageNo;
    }
    if (this.state.pagination.count !== "") {
      url = url + "&count=" + this.state.pagination.count;
    }
    if (this.state.pagination.status !== "") {
      url = url + "&status=" + this.state.pagination.status;
    }
    if (this.state.pagination.catagory !== "") {
      url = url + "&catagory=" + this.state.pagination.catagory;
    }
    if (this.state.pagination.domain !== "") {
      url = url + "&domain=" + this.state.pagination.domain;
    }
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    }).then((response) => {
      this.setState({ loading: false });
      this.setState({ blogResponse: [] });
      let page = this.state.pagination;
      page.items = response.data.total_blogs_count;
      page.pageNo = response.data.page_no;
      page.totalPage = response.data.total_no_pages;
      page.count = response.data.count_per_page;
      this.setState({ pagination: page });
      if (response.status === 200 || response.status === 201) {
        if (response.data.blogs && response.data.blogs.length > 0) {
          console.log(response, "found data");

          // Sort the blogs based on modification_date
          const sortedBlogs = response.data.blogs.sort((a, b) => {
            return (
              new Date(b.modification_date) - new Date(a.modification_date)
            );
          });

          this.setState({ blogResponse: sortedBlogs });
          // this.getExamAndUpdateImage(filteredExams);
        } else {
          console.log("Details not found");
        }
      } else {
        console.log("Details not found");
      }
    });
  }

  getExamAndUpdateImage(examList) {
    let exams = [];
    examList.forEach((element) => {
      let exam = element;
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore/" + element.exam_id,
      }).then((response) => {
        console.log("exam" + JSON.stringify(response.data?.exam_image));
        if (response.data?.exam_image)
          exam.exam_image = response.data?.exam_image;
        exams.push(exam);
        this.setState({ blogResponse: exams });
      });
    });
  }

  onSearch(e) {
    let pagination = this.state.pagination;
    pagination.search_key = e.target.value;
    this.setState({ pagination });
    this.getBlogList();
  }

  modifyBlog(blogId) {
    this.props.history.push("/createblogpost?blogId=" + blogId);
  }

  activatePost(blogId) {
    axios({
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/blogs/v1/" + blogId,
      data: {
        blog_status: "active",
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.componentDidMount(); // Refresh the blog list after activation
          console.log("Blog post activated successfully");
        } else {
          console.log("Failed to activate blog post");
        }
      })
      .catch((error) => {
        console.log("Error activating blog post:", error);
      });
  }

  deletePost(blogId) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/blogs/v1/" + blogId,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          this.componentDidMount(); // Refresh the blog list after deletion
          console.log("Blog post deleted successfully");
        } else {
          console.log("Failed to delete blog post");
        }
      })
      .catch((error) => {
        console.log("Error deleting blog post:", error);
      });
  }

  instructionDataChange(field, e) {
    this.setState({ assistantsEmail: e.target.value });
  }

  changeAssistantStatus(email, methodType, action) {
    let data = {
      email: [],
    };
    data.email.push(email);
    axios({
      method: methodType,
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + this.state.exam_id + "/assistants",
      data: data,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.getAssistantsList(this.state.exam_id);
          let msg = "Assistant " + action + " successfully....";
          console.log(msg);
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  handlePageChange(pageNumber) {
    let pagination = this.state.pagination;
    pagination["pageNo"] = pageNumber;
    this.setState({ pagination: pagination });
    this.getBlogList();
  }

  pagination(field, e) {
    let pagination = this.state.pagination;
    pagination[field] = e.target.value;
    this.setState({ pagination: pagination });
    this.getBlogList();
  }

  async getBlogById(blogId) {
    const { data: response } = await axios
      .get("/api/blogs/v1/" + blogId)
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ blogDetails: response });
    this.setState({ blogData: this.state.blogDetails });
  }

  getExamChat(exam_id) {
    sessionStorage.setItem("exam_id", exam_id);
    this.props.history.push("/inbox?examId=" + exam_id);
  }

  myExams() {
    this.props.history.push("/instructor-exams");
  }

  createExam() {
    this.props.history.push("/createexam");
  }

  instructorlanding() {
    this.props.history.push("/instructorlanding");
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          {/* <div className="report--header small">
            <h3>My Exams</h3>
          </div> */}

          <div className="row">
            <Sidebar />
            <div className="cardsss col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="mt-3">
                <div className="Exams">
                  <div className="row">
                    <div className="col-12">
                      <div className="exam-tab">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="home-tab"
                              data-toggle="tab"
                              href="#home"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "";
                                this.setState({ pagination });
                                this.getBlogList();
                              }}
                              aria-controls="home"
                              aria-selected="true"
                            >
                              All Posts
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="contact-tab"
                              data-toggle="tab"
                              href="#contact"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "creating";
                                this.setState({ pagination });
                                this.getBlogList();
                              }}
                              aria-controls="contact"
                              aria-selected="false"
                            >
                              Creating Mode
                            </a>
                          </li>
                          <a
                            className={`nav-link ${
                              this.state.pagination.status === "updated"
                                ? "active"
                                : ""
                            }`}
                            id="contact-tab"
                            data-toggle="tab"
                            href="#contact"
                            role="tab"
                            onClick={() => {
                              let pagination = this.state.pagination;
                              pagination.status = "updated";
                              this.setState({ pagination });
                              this.getBlogList();
                            }}
                            aria-controls="contact"
                            aria-selected={
                              this.state.pagination.status === "updated"
                                ? "true"
                                : "false"
                            }
                          >
                            Edit in progress
                          </a>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="active-tab"
                              data-toggle="tab"
                              href="#active"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "active";
                                this.setState({ pagination });
                                this.getBlogList();
                              }}
                              aria-controls="active"
                              aria-selected="false"
                            >
                              Active
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="disabled-tab"
                              data-toggle="tab"
                              href="#disabled"
                              role="tab"
                              onClick={() => {
                                let pagination = this.state.pagination;
                                pagination.status = "disabled";
                                this.setState({ pagination });
                                this.getBlogList();
                              }}
                              aria-controls="disabled"
                              aria-selected="false"
                            >
                              Disabled
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div
                              className={
                                "row search-exam " +
                                (this.state.blogResponse.length > 0
                                  ? "m6show"
                                  : "m6hidden")
                              }
                            >
                              <div className="col-md-4">
                                <input
                                  className="form-control"
                                  type="search"
                                  id="search"
                                  placeholder="Search Posts"
                                  onChange={this.pagination.bind(
                                    this,
                                    "search"
                                  )}
                                />
                              </div>
                            </div>
                            <br />
                            <div
                              className={
                                "row search-exam no-exams " +
                                (!this.state.blogResponse.length ||
                                this.state.blogResponse.length === 0
                                  ? "m6show"
                                  : "m6hidden")
                              }
                            >
                              <div className="col-12">
                                No blog posts available
                              </div>
                            </div>

                            {this.state.blogResponse.map((blogDetails, i) => {
                              return (
                                <div
                                  className="container tab-container mb-3"
                                  key={i}
                                >
                                  <div className="border border-info">
                                    <div className="row">
                                      <div className="col-md-5">
                                        <div className="exam-info">
                                          <b>Title:</b> {blogDetails.blog_title}
                                          <br />
                                          <b>Domain: </b>{" "}
                                          {blogDetails.domain_name}
                                          <br />
                                          <b>Category: </b>{" "}
                                          {blogDetails.catagory_name}
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="exam-statusbar">
                                          <br />
                                          <br />
                                          <b>Status: </b>{" "}
                                          {blogDetails.blog_status}
                                          <br />
                                        </div>
                                      </div>
                                      <div className="col-md-4 text-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm w-75 mt-2 btn-ipad"
                                          style={{
                                            backgroundColor: "#006400",
                                            color: "white",
                                          }}
                                          onClick={() =>
                                            this.activatePost(
                                              blogDetails.blog_id
                                            )
                                          }
                                        >
                                          Activate
                                        </button>

                                        <br />
                                        <button
                                          type="button"
                                          className="btn btn-secondary btn-sm w-75 mt-2 btn-ipad"
                                          onClick={() =>
                                            this.modifyBlog(blogDetails.blog_id)
                                          }
                                        >
                                          Edit Blog
                                        </button>

                                        <Link
                                          to={`/blogs/${blogDetails.blog_id}`}
                                          className="btn btn-primary btn-sm w-75 mt-2 btn-ipad"
                                          style={{
                                            backgroundColor: "#007bff",
                                            color: "white",
                                          }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Blog
                                        </Link>

                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm w-75 mt-2 btn-ipad"
                                          onClick={() =>
                                            this.deletePost(blogDetails.blog_id)
                                          }
                                        >
                                          Delete Post
                                        </button>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {this.state.pagination.totalPage < Number(2) ? (
                              <></>
                            ) : (
                              <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.pagination.pageNo}
                                itemsCountPerPage={this.state.pagination.count}
                                totalItemsCount={Number(
                                  this.state.pagination.items
                                )}
                                pageRangeDisplayed={
                                  this.state.pagination.totalPage
                                }
                                onChange={this.handlePageChange.bind(this)}
                              />
                            )}
                          </div>
                        </div>
                        <Modal show={this.state.showHide}>
                          <Modal.Header
                            closeButton
                            onClick={() => this.handleModalShowHide(0)}
                          ></Modal.Header>
                          <Modal.Footer></Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
