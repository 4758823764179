import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { checkUser } from "../utils/authHelper"; // Adjust the path accordingly

import axios from "axios";
import "./Style.css";

export default class BioDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: "",
      district: "",
      birth_year: "",
      primary_goal: "",
      secondary_goals: [],
      goalselect: false,
      primary_goalList: [],
      domainList: [],
      secondarydomainList: [],
      checkRedirect: false,
      countries: [],
      selectedCountry: "",
    };
    this.getList();
  }

  async componentDidMount() {
    // Use the utility function for checking user authentication
    try {
      await checkUser(this.props);
      this.fetchCountries(); // Fetch countries when the component mounts
    } catch (error) {
      console.log(error.message);
    }
  }

  getYearDropList = () => {
    const year = new Date().getFullYear() - 5;
    return Array.from(new Array(75), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ));
  };

  setStateId = (e) => {
    for (let i = 0; i < this.state.StateDistrict.length; i++) {
      if (e.target.value === this.state.StateDistrict[i].id) {
        this.setState({ District: this.state.StateDistrict[i].districts });
      }
    }
  };

  setstate1 = (e) => {
    this.setState({ state1: e.target.value });
  };

  setDistrict(e) {
    this.setState({ district: e.target.value });
  }

  setPrimaryGoals = (e) => {
    this.setState({ primary_goal: e.target.value });
    let newSecondaryCatList = [];
    for (let i = 0; i < this.state.domainList.length; i++) {
      if (this.state.domainList[i].catagory_id !== e.target.value) {
        newSecondaryCatList.push(this.state.domainList[i]);
      }
    }
    this.setState({ secondarydomainList: newSecondaryCatList });
  };

  setSecondaryGoals = (e) => {
    this.setState({ secondary_goals: e.target.value });
  };

  goalhide = () => {
    let birth_year = this.state.birth_year;
    let gender = this.state.gender;
    if (!birth_year || !gender) {
      console.log("Birth year and Gender are mandatory");
      return;
    } else {
      this.setState({ goalselect: true });
    }
  };

  goalshow = () => {
    this.setState({ goalselect: false });
  };

  setBirthyear = (e) => {
    this.setState({ birth_year: e.target.value });
  };

  setGender = (e) => {
    this.setState({ gender: e.target.value });
  };

  async getList() {
    const { data: response } = await axios
      .get("/api/domains/?status=active")
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ domainList: response.domains });
    this.setState({ secondarydomainList: response.domains });
    console.log(this.state.domainList);
  }

  onLoginFormSubmit() {
    const { primary_goal, birth_year, secondary_goals, state1, gender } =
      this.state;

    // Check if birth_year and gender are provided
    if (!birth_year || !gender) {
      console.log("Birth year and Gender are mandatory");
      return;
    } else {
      this.setState({ goalselect: true });
    }

    // Check if primary_goal is provided
    if (!primary_goal || primary_goal === "") {
      console.log("Primary goal is a mandatory field");
      return false;
    }

    // Check if primary and secondary goals are different
    if (primary_goal && secondary_goals && primary_goal === secondary_goals) {
      console.log("Select primary and secondary goals that are different");
      return false;
    }

    // Prepare the data to be submitted
    const data = {
      primary_goal: primary_goal,
      birth_year: birth_year,
      secondary_goals: secondary_goals,
      state1: state1,
      gender: gender,
    };

    // Make the POST request to submit goals
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/goals",
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          // Goals submitted successfully, proceed to fetch profile info
          this.fetchUserProfile();
        } else {
          console.log("Please fill in complete details and submit");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          console.log(error.response.data.errors[0].message);
        } else {
          console.log("An unexpected error occurred:", error.message);
        }
      });
  }

  // Function to fetch user profile and handle redirection based on email verification
  fetchUserProfile() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/profile",
    })
      .then((response) => {
        if (response && response.data) {
          const userProfile = response.data;

          // Check if the email is verified
          if (!userProfile.is_email_verified) {
            // Redirect to email verification page if email is not verified
            this.props.history.push("/verifyuser");
          } else {
            // If email is verified, redirect to the student landing page
            this.props.history.push("/studentlanding");
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          console.log(error.response.data.errors[0].message);
        } else {
          console.log("An unexpected error occurred:", error.message);
        }
      });
  }

  logOut() {
    sessionStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    axios({
      method: "POST",
      url: "/api/users/signout",
      Headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        this.setState({ checkRedirect: true });
        // window.location.reload();
      })
      .catch((error) => {
        this.setState({ checkRedirect: true });
        console.log(error.response.data.errors[0].message);
        // console.log(error.message)
        // console.log(error.response.data.errors[0].message);
      });
  }

  // Method to fetch countries from the API
  fetchCountries = () => {
    axios
      .get("https://restcountries.com/v3.1/all?fields=name")
      .then((response) => {
        const fetchedCountries = response.data;

        // Prioritize countries
        const prioritizedCountries = [
          "India",
          "United States",
          "Indonesia",
          "Brazil",
          "Nigeria",
          "Bangladesh",
          "Russia",
          "China",
          "Japan",
          "Pakistan",
          "Mexico",
          "Philippines",
          "Vietnam",
          "Germany",
          "Iran",
          "Turkey",
          "United Kingdom",
          "Thailand",
          "France",
          "Italy",
          "Egypt",
          "South Korea",
          "Kenya",
          "Spain",
          "Colombia",
          "Argentina",
          "Ukraine",
          "Algeria",
          "Canada",
          "Saudi Arabia",
          "Poland",
          "South Africa",
          "Iraq",
          "Malaysia",
          "Peru",
          "Myanmar",
          "Morocco",
          "Australia",
          "Tanzania",
          "Venezuela",
          "Taiwan",
          "Nepal",
          "Ethiopia",
          "Chile",
          "Uganda",
          "Uzbekistan",
          "Kazakhstan",
          "Netherlands",
        ];

        // Merge prioritized countries with fetched countries
        const mergedCountries = [
          ...prioritizedCountries.map((country) => {
            const foundCountry = fetchedCountries.find(
              (c) => c.name.common === country
            );
            return foundCountry ? foundCountry : { name: { common: country } };
          }),
          ...fetchedCountries.filter(
            (country) => !prioritizedCountries.includes(country.name.common)
          ),
        ];

        this.setState({ countries: mergedCountries });
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  };

  // Method to handle changes in the selected country
  handleCountryChange = (event) => {
    this.setState({ selectedCountry: event.target.value });
  };

  render() {
    if (this.state.checkRedirect === true) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    return (
      <div>
        <div>
          <div className="container screen-padding py-3">
            {/* <div className="row"> */}
            <div className="col-md-12">
              {/* <hr className="mb-4" /> */}
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <span className="anchor" id="formLogin" />
                  <div>
                    <h6 className="text-center profile-h4 text-info">
                      Onboarding - Let Us Know You Better!
                    </h6>
                    {/* <h6 className="text-center text-warning">
                        Select Your Goals,You Can Change Your Goals Anytime
                      </h6> */}
                    <div className="card-body">
                      <form
                        autoComplete="off"
                        className="form"
                        id="formLogin"
                        name="formLogin"
                      >
                        <div className="form-group">
                          <label htmlFor="countrySelect">
                            Select Your Country
                          </label>
                          <select
                            className="form-control"
                            id="countrySelect"
                            onChange={this.handleCountryChange}
                            value={this.state.selectedCountry}
                          >
                            <option value="">Select</option>
                            {this.state.countries.map((country, index) => (
                              <option key={index} value={country.name.common}>
                                {country.name.common}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Select Your Birth Year</label>
                          <select
                            className="form-control "
                            id="uname1"
                            name="uname1"
                            required
                            type="text"
                            onChange={this.setBirthyear}
                            value={this.state.birth_year}
                          >
                            <option value="">Select</option>
                            {this.getYearDropList()}
                          </select>
                        </div>
                        <label>Select Your Gender</label>
                        <div className="form-group row">
                          <div className="col-4">
                            <input
                              id="radio-1"
                              name="radio"
                              type="radio"
                              value="1"
                              onChange={this.setGender}
                            />
                            <label className="gender-label" htmlFor="uname1">
                              Male
                            </label>{" "}
                            &nbsp;
                          </div>
                          <div className="col-4">
                            <input
                              id="radio-1"
                              name="radio"
                              type="radio"
                              value="2"
                              onChange={this.setGender}
                            />
                            <label className="gender-label" htmlFor="uname1">
                              Female
                            </label>{" "}
                            &nbsp;
                          </div>
                          <div className="col-4">
                            <input
                              id="radio-1"
                              name="radio"
                              type="radio"
                              value="3"
                              onChange={this.setGender}
                            />
                            <label className="gender-label" htmlFor="uname1">
                              Other
                            </label>{" "}
                            &nbsp;
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="uname1">Your Primary Goal</label>
                          <select
                            className="form-control "
                            id="uname1"
                            name="uname1"
                            required
                            type="text"
                            onChange={this.setPrimaryGoals}
                            value={this.state.primary_goal}
                          >
                            <option value="">Select</option>
                            {this.state.domainList.map((item, i) => {
                              return (
                                <option key={i} value={item.domain_id}>
                                  {" "}
                                  {item.domain}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Your Secondary Goal</label>
                          <select
                            className="form-control"
                            id="goals2"
                            name="secondaryGoals"
                            required
                            type="text"
                            onChange={this.setSecondaryGoals}
                            value={this.state.secondary_goals}
                          >
                            <option value="">Select</option>
                            {this.state.secondarydomainList.map((item, i) => {
                              return (
                                <option key={i} value={item.domain_id}>
                                  {" "}
                                  {item.domain}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        {/* <button
                            className="btn btn-primary width-30"
                            type="button"
                            onClick={this.goalshow}
                          >
                            Back
                          </button>{" "} */}
                        <br></br>
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-primary width-30"
                            type="button"
                            onClick={() => this.onLoginFormSubmit()}
                          >
                            Proceed
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <button
          id="scroll-to-top"
          className="btn btn-primary btn-lg"
          title="Scroll To Top"
          data-toggle="tooltip"
          data-placement="left"
        >
          <i className="fa fa-arrow-up" />
        </button>
      </div>
    );
  }
}
