import React from "react";
import "./App.css";
import Home from "./Components/Pages/HomePage/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainLayout from "./MainLayout";
import SignUp from "./Components/signup/SignUp";
import SignIn from "./Components/signup/SignIn";
import SignIntUp from "./Components/signup/SignIntUp";
// import OtpVerification from './Components/signup/OtpVerification';
import StudentLanding from "./Components/Pages/student/StudentLanding";
// import GoalSelect from './Components/signup/GoalSelect';
import ExamPage from "./Components/Pages/Exam/ExamPage";
import BlogPostPage from "./Components/Pages/Exam/BlogPostPage";
import ExamReport from "./Components/Pages/Exam/ExamReport";
import Exam from "./Components/Pages/Exam/Exam";
import Post from "./Components/Pages/Exam/Post";
import InstructorSignup from "./Components/signup/InstructorSignup";
import VerifyUser from "./Components/signup/VerifyUser";
import CreateExam from "./Components/Pages/instructor/CreateExam";
import CreateBlogPost from "./Components/Pages/instructor/CreateBlogPost";
import Instructorlanding from "./Components/Pages/instructor/Instructorlanding";
import InstructorExams from "./Components/Pages/instructor/InstructorExams";
import InstructorBlogPost from "./Components/Pages/instructor/InstructorBlogPost";
import BlogDetails from "./Components/Pages/blog/BlogDetails";
import InsctBlogView from "./Components/Pages/blog/InsctBlogView";
import ExamCreater from "./Components/common/ExamCreater";
import StudentProfile from "./Components/Pages/profile/StudentProfile";
import BioDetails from "./Components/signup/BioDetails";
import InstructorProfile from "./Components/Pages/profile/InstructorProfile";
import ExamHistory from "./Components/Pages/student/ExamHistory";
import InstructorsDetails from "./Components/signup/InstructorsDetails";
import Subscription from "./Components/Pages/Subscription/Subscription";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Mail from "./Components/Mail/Mail";
import ForgotPassword from "./Components/signup/ForgotPasswordReset";
import ReadMail from "./Components/Mail/ReadMail";
import CategoryPage from "./Components/Pages/List Pages/CategoryPage";
import InstructorPage from "./Components/Pages/List Pages/InstructorPage";
import ReviewExam from "./Components/Pages/Exam/ReviewExam";
import InstructorsList from "./Components/Pages/List Pages/InstructorsList";
import InstructorsListPublic from "./Components/Pages/List Pages/InstructorsListPublic";
import DomainList from "./Components/Pages/List Pages/DomainList/DomainList";
import CategoriesList from "./Components/Pages/List Pages/Categories List/CategoriesList";
import DomainWiseCategoryList from "./Components/Pages/List Pages/DomainWiseCategoryList/DomainWiseCategoryList";
import Enrollments from "./Components/Pages/Enrollment/Enrollments";
import EnrollmentPosts from "./Components/Pages/Enrollment/EnrollmentPosts";
import ExamsHistory from "./Components/Pages/Enrollment/ExamsHistory";
import Transactions from "./Components/Pages/Subscription/transactions";
// import Sidebar from './Components/Sidebar';
import Policies from "./Components/Pages/Footer/Policies";
import ContactUs from "./Components/Pages/Footer/ContactUs";
import TermsConditions from "./Components/Pages/Footer/TermsConditions";
import ForgotPasswordEmail from "./Components/signup/ForgetPasswordEmail";
import { ToastProvider } from "./ToastProvider";
import ScrollToTop from "react-router-scroll-top";

function App() {
  return (
    <ToastProvider>
      {" "}
      <Router>
        <ScrollToTop /> {/* Place the ScrollToTop component here */}
        <Switch>
          <MainLayout>
            <Route path="/" exact component={Home} />
            <Route path="/sign-up" component={SignIntUp} />
            <Route path="/signup" component={SignUp} />
            <Route path="/signin" component={SignIn} />
            <Route path="/onboardingdetails" component={BioDetails} />
            <Route path="/studentlanding" component={StudentLanding} />
            <Route path="/exampage" component={ExamPage}></Route>
            <Route path="/blogpostpage" component={BlogPostPage}></Route>
            <Route path="/exam-report" component={ExamReport}></Route>
            <Route path="/exam" component={Exam}></Route>
            <Route path="/post" component={Post}></Route>
            <Route path="/instructorsignup" component={InstructorSignup} />
            <Route path="/verifyuser" component={VerifyUser} />
            <Route path="/createexam" component={CreateExam} />
            <Route path="/createblogpost" component={CreateBlogPost} />
            <Route path="/createexam/:examId" component={CreateExam} />
            <Route path="/instructorlanding" component={Instructorlanding} />
            <Route path="/instructor-exams" component={InstructorExams} />
            <Route path="/instructor-blogpost" component={InstructorBlogPost} />
            <Route exact path="/blog/:blogId" component={BlogDetails} />
            <Route exact path="/blogs/:blogId" component={InsctBlogView} />
            <Route path="/examcreater" component={ExamCreater} />
            <Route path="/studentprofile" component={StudentProfile} />
            <Route path="/instructorprofile" component={InstructorProfile} />
            <Route path="/exam-history" component={ExamHistory} />
            <Route path="/exams-history" component={ExamsHistory} />
            <Route path="/exam-history/:enrollmentId" component={ExamHistory} />
            <Route path="/instructor-details" component={InstructorsDetails} />
            <Route path="/subscription" component={Subscription} />
            <Route path="/inbox" component={Mail} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route
              path="/forgotpassword-emailentry"
              component={ForgotPasswordEmail}
            />
            <Route path="/mail" component={ReadMail} />
            <Route path="/category-page" component={CategoryPage} />
            <Route path="/instructor-page" component={InstructorPage} />
            <Route path="/exam-review" component={ReviewExam} />
            <Route path="/instructors" component={InstructorsList} />
            <Route
              path="/instructors-public"
              component={InstructorsListPublic}
            />
            <Route path="/domains" component={DomainList} />
            <Route path="/categories" component={CategoriesList} />
            <Route path="/enrolled-exams" component={Enrollments} />
            <Route path="/enrolled-posts" component={EnrollmentPosts} />
            <Route path="/transactions" component={Transactions} />
            <Route
              path="/domain-wise-categories"
              component={DomainWiseCategoryList}
            />
            <Route
              path="/domain-wise-categories/:domainId"
              component={DomainWiseCategoryList}
            />
            <Route path="/about-us" component={Policies} />

            <Route path="/contact-us" component={ContactUs} />
            <Route path="/terms-conditions" component={TermsConditions} />
          </MainLayout>
        </Switch>
        <ScrollToTop smooth />
      </Router>
    </ToastProvider>
  );
}
export default App;
