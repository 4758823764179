/* eslint-disable react/no-deprecated */

import React, { Component } from "react";
import "./CreateExam.css";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
window.jQuery = $;
require("jquery/dist/jquery");
require("bootstrap");
require("popper.js/dist/umd/popper");
require("bootstrap/dist/js/bootstrap");

export default class CreateExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueEditor: "",
      errors: {},
      examTitle: "",
      examSubTitle: "",
      examDescription: "",
      emptyError: "",
      loading: true,
      newExamData: {
        neg_score_strategy: 0,
        exam_id: null,
        token: "",
        email: "",
        exam_desc: "",
        exam_code: null,
        exam_style: "",
        exam_title: "",
        exam_subtitle: "",
        exam_type: "",
        exam_mode: "",
        study_mode: false,
        instructions: {
          1: "",
          2: "",
          3: "",
          4: "",
          5: "",
        },
        exam_creator_id: "",
        progression: "0%",
      },
      categoryList: [],
      domainList: [],
      assistants: [],
      questionsList: [],
      currentQuestion: 0,
      currentTab: 1,
      showHide: false,
      showHide1: false,
      showHide3: false,
      deletionList: [],
      changedQuestionsCount: 0,
      is_submitted: false,
      assistantsEmail: "",
      showAssistantStatus: false,
      isFinalSubmit: false,
      RedirectNew: false,
      defaultTextSummerNote: "Correct answer is option A.",
      editorInitialized: false,
      editorRef: React.createRef(),
    };
  }

  /**
   * lifecylce method
   */
  componentDidMount() {
    // we introduce the editorInitialized state variable to keep track of whether the Summernote
    //editor has been initialized.We use a setInterval function to check for the presence of the editor
    //element every 100 milliseconds.Once the editor is found, the CSS class is added, and the interval is cleared.
    const intervalId = setInterval(() => {
      const editor = document.querySelector(".note-editable");
      if (editor) {
        editor.classList.add("left-aligned");
        clearInterval(intervalId);
      }
    }, 100);
    // this.checkUser();
    window.scrollTo(0, 0);
    let search = new URLSearchParams(this.props.location.search);
    if (search) {
      let examId = search.get("examId");
      let email = search.get("email");
      let token = search.get("token");
      if (examId && email && token) {
        let newExamData = this.state.newExamData;
        newExamData.token = token;
        newExamData.email = email;
        newExamData.examId = examId;
        this.setState({ newExamData });
        this.validateExamById(examId);
      } else if (examId && email) {
        let newExamData = this.state.newExamData;
        newExamData.email = email;
        this.setState({ newExamData });
        this.handleModalShowHide1();
      } else if (examId) {
        this.getExamById(examId);
      }
    }
    this.getDomainList();
  }

  handleBackClick() {
    window.history.back();
  }

  // checkUser() {
  //     axios({
  //         method: 'GET',
  //         url: '/api/users/currentuser'
  //     }).then(response => {
  //         // if (response.data.current_user === null) {
  //         //     swal("Please login first")
  //         //     this.props.history.push('/signin')
  //         // } else {
  //         //     console.log('logged in')
  //         // }
  //     }).catch(error => {
  //         swal(error.message)
  //     })
  // }

  validateExamById(examId) {
    let url;
    let showHide1 = false;
    this.setState({ showHide1 });
    if (this.state.newExamData.email && this.state.newExamData.token) {
      url =
        "/api/examstore/" +
        examId +
        "/assistants?email=" +
        this.state.newExamData.email +
        "&token=" +
        this.state.newExamData.token;
    } else {
      url = "/api/examstore/" + examId;
    }
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (this.state.newExamData.email) {
          response.data.email = this.state.newExamData.email;
        }
        if (this.state.newExamData.token) {
          response.data.token = this.state.newExamData.token;
        }
        this.setState({ newExamData: response.data });
        if (
          this.state.newExamData &&
          this.state.newExamData.neg_score_strategy &&
          this.state.newExamData.neg_score_strategy > 0
        ) {
          let newExamData = this.state.newExamData;
          newExamData.negative_marking = "Yes";
          this.setState({ newExamData });
        } else {
          let newExamData = this.state.newExamData;
          newExamData.negative_marking = "No";
          this.setState({ newExamData });
        }
        this.setState({ questionsList: response.data.exam_questions });
        if (this.state.questionsList && this.state.questionsList.length > 0) {
          let remainingCount =
            this.state.newExamData.number_of_questions -
            this.state.questionsList.length;
          let questionsList = this.state.questionsList;
          if (!questionsList) {
            questionsList = [];
          }
          let prevLength = this.state.questionsList.length;
          let i = 0;
          while (i < remainingCount) {
            let question = {
              question_domain: "",
              question_number: prevLength + i + 1,
              question: "",
              answer_choice: {
                a: "",
                b: "",
                c: "",
                d: "",
                e: "",
              },
              score: "",
              negative_score: "",
              showStatus: false,
              correct_answer: "",
              rich_text: false,
            };
            questionsList.push(question);
            i++;
          }
          for (let k = 0; k < questionsList.length; k++) {
            $("#s1").summernote("code", "<h1>Hello</h1>");
            questionsList[k].showStatus = false;
          }
          questionsList[0].showStatus = true;
          this.setState({ questionsList });
        } else {
          this.createQuestionsArray(this.state.newExamData.number_of_questions);
        }
        this.getCategoryList(response.data.domain_id);
        this.getAssistantsList(examId);
      })
      .catch(() => {
        console.log("Enter valid token.");
        this.handleModalShowHide1();
      });
  }

  getExamById(examId) {
    let url;
    let showHide1 = false;
    this.setState({ showHide1 });
    if (this.state.newExamData.email && this.state.newExamData.token) {
      url =
        "/api/examstore/" +
        examId +
        "/assistants?email=" +
        this.state.newExamData.email +
        "&token=" +
        this.state.newExamData.token;
    } else {
      url = "/api/examstore/" + examId;
    }
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        if (this.state.newExamData.email) {
          response.data.email = this.state.newExamData.email;
        }
        if (this.state.newExamData.token) {
          response.data.token = this.state.newExamData.token;
        }
        this.setState({ newExamData: response.data });
        if (
          this.state.newExamData &&
          this.state.newExamData.neg_score_strategy &&
          this.state.newExamData.neg_score_strategy > 0
        ) {
          let newExamData = this.state.newExamData;
          newExamData.negative_marking = "Yes";
          this.setState({ newExamData });
        } else {
          let newExamData = this.state.newExamData;
          newExamData.negative_marking = "No";
          this.setState({ newExamData });
        }
        let qList = [];
        response.data.answer_set.forEach((ansset) => {
          response.data.exam_questions.forEach((qset) => {
            let question = qset;
            if (question.question_number === ansset.question_number) {
              question.correct_answer = ansset.correct_answer;
              question.reason = ansset.reason;
              qList.push(question);
            }
          });
        });
        this.setState({ questionsList: qList });
        if (this.state.questionsList && this.state.questionsList.length > 0) {
          let remainingCount =
            this.state.newExamData.number_of_questions -
            this.state.questionsList.length;
          let questionsList = this.state.questionsList;
          if (!questionsList) {
            questionsList = [];
          }
          let prevLength = this.state.questionsList.length;
          let i = 0;
          while (i < remainingCount) {
            let question = {
              question_domain: "",
              question_number: prevLength + i + 1,
              question: "",
              answer_choice: {
                a: "",
                b: "",
                c: "",
                d: "",
                e: "",
              },
              score: "",
              negative_score: "",
              showStatus: false,
              correct_answer: "",
              rich_text: false,
            };
            questionsList.push(question);
            i++;
          }
          for (let k = 0; k < questionsList.length; k++) {
            $("#s1").summernote("code", "<h1>Hello</h1>");
            questionsList[k].showStatus = false;
          }
          questionsList[0].showStatus = true;
          this.setState({ questionsList });
        } else {
          this.createQuestionsArray(this.state.newExamData.number_of_questions);
        }
        this.getCategoryList(response.data.domain_id);
        this.getAssistantsList(examId);
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  //The toBlob method converts the canvas content into a Blob object.If the resulting
  //blob size is within the target size limit, the compressed image URL is obtained
  //using URL.createObjectURL and passed to the insertImage function.
  //the newWidth and newHeight variables are introduced to calculate the resized dimensions
  //while maintaining the aspect ratio of the original image.The resizing logic is then
  //performed using these updated dimensions to ensure that the image is not distorted.
  onImageUpload = (images, insertImage) => {
    const maxSize = 1024000; // Maximum allowed image size in bytes (1MB)
    const targetSize = 800000; // Target image size in bytes (800KB)
    const maxWidth = 200; // Maximum allowed image width
    const maxHeight = 200; // Maximum allowed image height
    const compressionQuality = 0.8; // Compression quality (adjust as needed)

    if (images[0].size <= maxSize) {
      for (let i = 0; i < images.length; i++) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = function () {
            let width = image.width;
            let height = image.height;
            let newWidth = width;
            let newHeight = height;

            if (width > maxWidth || height > maxHeight) {
              const scale = Math.min(maxWidth / width, maxHeight / height);
              newWidth = width * scale;
              newHeight = height * scale;
            }

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(image, 0, 0, newWidth, newHeight);
            canvas.toBlob(
              (blob) => {
                if (blob.size <= targetSize) {
                  const compressedImageURL = URL.createObjectURL(blob);
                  insertImage(compressedImageURL);
                } else {
                  console.log(
                    "Image not saved, max allowed image size is 800KB"
                  );
                }
              },
              "image/jpeg",
              compressionQuality // Compression quality
            );
          };
        };
        reader.readAsDataURL(images[i]);
      }
    } else {
      console.log("Image not saved, max allowed image size is 1MB");
    }
  };

  // onImageUpload(images, insertImage) {
  //   if (images[0].size <= 100000) {
  //     for (let i = 0; i < images.length; i++) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         var i = new Image();
  //         i.src = reader.result;
  //         i.onload = function () {
  //           if (i.width <= 200 && i.height <= 200) {
  //             insertImage(reader.result);
  //           } else {
  //             console.log(
  //               "Image not saved, image width and height should be less than 200*200"
  //             );
  //           }
  //         };
  //       };
  //       reader.readAsDataURL(images[i]);
  //     }
  //   } else {
  //     console.log("Image not saved, max allowed image size is 100kb");
  //   }
  // }

  getAssistantsList(examId) {
    if (
      examId &&
      !this.state.newExamData.token &&
      !this.state.newExamData.email
    ) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore/" + examId + "/assistants",
      })
        .then((response) => {
          let assistants = [];
          let assistantsresp = response.data.assistants;
          if (assistantsresp && assistantsresp.length > 0) {
            for (let i = 0; i < assistantsresp.length; i++) {
              if (assistantsresp[i].validity !== "disabled") {
                assistants.push(assistantsresp[i]);
              }
            }
            this.setState({ assistants });
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  changeAssistantStatus(email, methodType, action) {
    let data = {
      email: [],
    };
    data.email.push(email);
    axios({
      method: methodType,
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + this.state.newExamData.exam_id + "/assistants",
      data: data,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.getAssistantsList(this.state.newExamData.exam_id);
          console.log("Assistant " + action + " successfully....");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  handleModalShowHide1() {
    this.setState({ showHide1: !this.state.showHide1 });
  }

  handleModalShowHide3() {
    this.setState({ showHide3: !this.state.showHide3 });
  }

  validateData() {
    if (this.state.newExamData.email && this.state.newExamData.token) {
      let search = new URLSearchParams(this.props.location.search);
      if (search) {
        let examId = search.get("examId");
        this.getExamById(examId);
      }
    } else {
      console.log("Fill required details");
    }
  }

  showQuestionsChangesModal(number_of_questions) {
    this.setState({ changedQuestionsCount: number_of_questions });
    this.setState({ showHide3: true });
  }

  deleteLastQuestions() {
    let count =
      this.state.changedQuestionsCount -
      this.state.newExamData.number_of_questions;
    let questions = this.state.questionsList;
    let newQuestionList = questions.slice(
      0,
      Math.max(questions.length - count)
    );
    let j = 0;
    while (j < newQuestionList.length) {
      newQuestionList[j].question_number = j + 1;
      j++;
    }
    newQuestionList[0].showStatus = true;
    this.setState({ questionsList: newQuestionList });
    let newExamData = this.state.newExamData;
    newExamData.number_of_questions = newQuestionList.length;
    this.setState({ newExamData });
    this.setState({ showHide: false });
    this.setState({ showHide3: false });
    this.setState({ currentQuestion: 0 });
    this.onExamFormSubmit();
  }

  deleteManually() {
    this.setState({ showHide3: false });
    this.setState({ showHide: true });
  }

  handleNext() {
    document.getElementById("menu1").className = "active";
  }

  examDataChange(field, e) {
    let newExamData = this.state.newExamData;
    newExamData[field] = e.target.value;
    this.setState({ newExamData });
    if (
      (field === "number_of_questions" || field === "total_exam_score") &&
      !this.state.newExamData.exam_id
    ) {
      this.createQuestionsArray(this.state.newExamData.number_of_questions);
    } else if (field === "total_exam_score" && this.state.newExamData.exam_id) {
      console.log("");
    }
    if (field === "domain_id") {
      let domaniId = e.target.value;
      this.getCategoryList(domaniId);
    }
    if (field === "exam_style") {
      if (this.state.newExamData.exam_style === "non-competitive") {
        let newExamData = this.state.newExamData;
        newExamData.pass_score = 0;
        newExamData.neg_score_strategy = 0;
        newExamData.negative_marking = "No";
        this.setState({ newExamData });
      }
    }
    if (field === "study_mode") {
      console.log(this.state.newExamData.study_mode);
      newExamData.study_mode = this.state.newExamData.study_mode;
      this.setState({ newExamData });
    }
    if (
      field === "negative_marking" &&
      this.state.newExamData.exam_style === "non-competitive" &&
      e.target.value === "Yes"
    ) {
      let newExamData = this.state.newExamData;
      // newExamData.pass_score = 0;
      newExamData.neg_score_strategy = 0;
      newExamData.negative_marking = "No";
      this.setState({ newExamData });
      console.log("Select exam style competitive, for negative marking Yes");
    }
    if (
      field === "neg_score_strategy" &&
      this.state.newExamData.number_of_questions &&
      this.state.newExamData.total_exam_score &&
      !this.state.newExamData.exam_id
    ) {
      this.setNegativeScore();
    }
  }

  setNegativeScore() {
    let questions = this.state.questionsList;
    let newQuestionList = [];
    let j = 0;
    while (j < questions.length) {
      let q = questions[j];
      q.negative_score =
        (parseFloat(q.score) *
          parseFloat(this.state.newExamData.neg_score_strategy)) /
        100;
      newQuestionList.push(q);
      j++;
    }
    this.setState({ questionsList: newQuestionList });
  }

  saveQuestionsEditorData(data, field) {
    let questionsList = this.state.questionsList;
    questionsList[this.state.currentQuestion][field] = data
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    this.setState({ questionsList });
  }

  instructionDataChange(field, e) {
    if (field === "assistantEmail") {
      this.setState({ assistantsEmail: e.target.value });
    } else {
      let newExamData = this.state.newExamData;
      if (!newExamData.instructions) {
        newExamData.instructions = {};
      }
      newExamData["instructions"][field] = e.target.value;
      this.setState({ newExamData });
    }
  }

  questionDataChange(field, e) {
    let i = 0;
    let questionsList = this.state.questionsList;
    while (i < this.state.questionsList.length) {
      if (i === this.state.currentQuestion) {
        if (
          field === "a" ||
          field === "b" ||
          field === "c" ||
          field === "d" ||
          field === "e"
        ) {
          questionsList[i]["answer_choice"][field] = e.target.value;
        } else {
          questionsList[i][field] = e.target.value;
          if (
            (field === "score" || field === "question_domain") &&
            this.state.newExamData.negative_marking &&
            this.state.newExamData.negative_marking === "Yes"
          ) {
            if (
              questionsList[i].score &&
              this.state.newExamData.neg_score_strategy
            ) {
              questionsList[i].negative_score =
                (parseInt(questionsList[i].score) *
                  parseInt(this.state.newExamData.neg_score_strategy)) /
                100;
            } else {
              questionsList[i].negative_score = 0;
            }
          } else if (field === "score" || field === "question_domain") {
            questionsList[i].negative_score = 0;
          }
        }
      }
      i++;
    }
    this.setState({ questionsList });
  }

  createQuestionsArray(number_of_questions) {
    let questionsList = [];
    let i = 0;
    let score = 0;
    if (
      this.state.newExamData.total_exam_score &&
      number_of_questions &&
      number_of_questions > 0
    ) {
      score = this.state.newExamData.total_exam_score / number_of_questions;
    }
    while (i < number_of_questions) {
      if (i === 0) {
        let question = {
          question_domain: "",
          question_number: i + 1,
          question: "",
          answer_choice: {
            a: "",
            b: "",
            c: "",
            d: "",
            e: "",
          },
          score: score,
          negative_score: "",
          showStatus: true,
          correct_answer: "",
          rich_text: false,
        };
        questionsList.push(question);
      } else {
        let question = {
          question_domain: "",
          question_number: i + 1,
          question: "",
          answer_choice: {
            a: "",
            b: "",
            c: "",
            d: "",
            e: "",
          },
          score: score,
          negative_score: "",
          showStatus: false,
          correct_answer: "",
          rich_text: false,
        };
        questionsList.push(question);
      }
      i++;
    }
    this.setState({ questionsList });
  }

  async getCategoryList(domaniId) {
    const { data: response } = await axios
      .get("/api/catagories?domain_id=" + domaniId)
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    var i = 0;
    while (i < response.catagories.length) {
      response.catagories[i].domainName = response.catagories[i].domain.domain;
      i++;
    }
    this.setState({ categoryList: response.catagories });
  }

  async getDomainList() {
    const { data: response } = await axios
      .get("/api/domains?page_no=1&count=200&status=active")
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ domainList: response.domains });
    this.setState({ loading: false });
  }

  restructureQuestionsAndSubmit() {
    let deletionCount =
      this.state.changedQuestionsCount -
      this.state.newExamData.number_of_questions;
    if (
      this.state.deletionList &&
      deletionCount === this.state.deletionList.length
    ) {
      this.deleteQuestions();
    } else {
      let result = window.confirm(
        "Questions marked for delete:" +
          this.state.deletionList.length +
          ", doesn't match with required:" +
          deletionCount +
          ".Confirm to continue"
      );
      if (result) {
        this.deleteQuestions();
      } else {
        console.log("Action cancelled");
      }
    }
  }

  deleteQuestions() {
    let questions = this.state.questionsList;
    let newQuestionList = [];
    let i = 0;
    while (i < questions.length) {
      let x = 0;
      let delList = this.state.deletionList;
      for (let k = 0; k < delList.length; k++) {
        if (questions[i].question_number === delList[k]) {
          x = x + 1;
          break;
        }
      }
      if (x === 0) {
        newQuestionList.push(questions[i]);
      }
      i++;
    }
    // let score = 0;
    // if (this.state.newExamData.total_exam_score && newQuestionList && newQuestionList.length > 0) {
    //     score = this.state.newExamData.total_exam_score / newQuestionList.length;
    // }
    let j = 0;
    while (j < newQuestionList.length) {
      newQuestionList[j].question_number = j + 1;
      // newQuestionList[j].score = score;
      j++;
    }
    newQuestionList[0].showStatus = true;
    this.setState({ questionsList: newQuestionList });
    let newExamData = this.state.newExamData;
    newExamData.number_of_questions = newQuestionList.length;
    this.setState({ newExamData });
    this.setState({ showHide: false });
    this.setState({ showHide3: false });
    this.onExamFormSubmit();
    // this.setState({ currentTab: 2 });
  }

  finalSubmit() {
    let new_total_exam_score = 0;
    let questionsList = this.state.questionsList;
    let i = 0;
    while (i < questionsList.length) {
      if (questionsList[i].score) {
        new_total_exam_score =
          new_total_exam_score + parseFloat(questionsList[i].score);
      }
      i++;
    }
    if (new_total_exam_score !== this.state.newExamData.total_exam_score) {
      let result = window.confirm(
        "Total exam score does not match with question wise sum of score.Total exam score is " +
          this.state.newExamData.total_exam_score +
          ", sum of question wise score is " +
          new_total_exam_score +
          " .Confirm to update and continue"
      );
      if (result) {
        let finalExamData = this.state.newExamData;
        finalExamData.total_exam_score = new_total_exam_score;
        this.setState({ newExamData: finalExamData });
        this.setState({ isFinalSubmit: true });
        this.onExamFormSubmit();
      } else {
        console.log("Action cancelled, please review question wise score");
      }
    } else {
      this.setState({ isFinalSubmit: true });
      this.onExamFormSubmit();
    }
  }

  onExamFormSubmit() {
    let finalExamData = this.state.newExamData;
    let questionsList = this.state.questionsList;
    finalExamData.exam_questions = [];
    finalExamData.answer_set = [];
    finalExamData.exam_status = "creating";
    if (finalExamData.total_exam_score)
      finalExamData.total_exam_score = parseInt(finalExamData.total_exam_score);
    if (finalExamData.pass_score)
      finalExamData.pass_score = parseInt(finalExamData.pass_score);

    if (finalExamData.number_of_questions)
      finalExamData.number_of_questions = parseInt(
        finalExamData.number_of_questions
      );
    // if (finalExamData.total_exam_score && finalExamData.pass_score) {
    //     finalExamData.pass_percent = (100 * finalExamData.pass_score) / finalExamData.total_exam_score;
    // }
    if (
      this.state.newExamData.negative_marking &&
      this.state.newExamData.negative_marking === "No"
    ) {
      finalExamData.neg_score_strategy = 0;
    }
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));
    if (instructorData && instructorData.user_id) {
      finalExamData.exam_creator_id = instructorData.user_id;
    }
    if (questionsList && questionsList.length > 0) {
      let i = 0;
      while (i < questionsList.length) {
        if (
          questionsList[i].question_domain &&
          questionsList[i].question_number &&
          questionsList[i].question &&
          questionsList[i].answer_choice &&
          questionsList[i].score &&
          questionsList[i].reason &&
          (questionsList[i].negative_score > 0 ||
            questionsList[i].negative_score === 0)
        ) {
          questionsList[i].question_number = parseInt(
            questionsList[i].question_number
          );
          questionsList[i].score = parseFloat(questionsList[i].score);
          questionsList[i].negative_score = parseFloat(
            questionsList[i].negative_score
          );
          if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "a"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.a;
            questionsList[i].correct_answer = "a";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "b"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.b;
            questionsList[i].correct_answer = "b";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "c"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.c;
            questionsList[i].correct_answer = "c";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "d"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.d;
            questionsList[i].correct_answer = "d";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "e"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.e;
            questionsList[i].correct_answer = "e";
          }
          finalExamData.exam_questions.push(questionsList[i]);
          let ans = {
            question_number: parseInt(questionsList[i].question_number),
            correct_answer: questionsList[i].correct_answer,
            reason: questionsList[i].reason,
            score: parseFloat(questionsList[i].score),
            negative_score: parseFloat(questionsList[i].negative_score),
          };
          finalExamData.answer_set.push(ans);
        }
        i++;
      }
    }
    if (
      finalExamData.exam_questions &&
      finalExamData.exam_questions.length > 0
    ) {
      let qLength = finalExamData.exam_questions.length;
      let progression =
        (100 * qLength) / this.state.newExamData.number_of_questions;
      finalExamData.progression = progression + "%";
    }
    if (null === this.state.newExamData.exam_id) {
      let validExamData = finalExamData;
      let newQuestionsArray = [];
      validExamData.exam_questions.forEach((element) => {
        // delete element.correct_answer;
        // delete element.reason;
        let ele = {
          question_domain: element.question_domain,
          question_number: element.question_number,
          question: element.question,
          answer_choice: {
            a: element.answer_choice.a,
            b: element.answer_choice.b,
            c: element.answer_choice.c,
            d: element.answer_choice.d,
            e: element.answer_choice.e,
          },
          score: element.score,
          negative_score: element.negative_score,
          showStatus: element.showStatus,
          rich_text: element.rich_text,
          is_valid: element.is_valid,
        };
        newQuestionsArray.push(ele);
      });
      validExamData.exam_questions = newQuestionsArray;
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore",
        data: validExamData,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let newExamData = this.state.newExamData;
            newExamData.exam_id = response.data.exam_id;
            newExamData.exam_code = response.data.exam_id;
            this.setState({ newExamData });
            console.log("Details saved successfully....");
            if (this.state.image) {
              this.uploadImage(response.data.exam_id);
            }
            if (
              !this.state.newExamData.token &&
              !this.state.newExamData.email
            ) {
              this.setState({ showAssistantStatus: true });
            } else {
              this.setState({ currentTab: 2 });
            }
          } else {
            console.log("Error while saving details");
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    } else {
      let method = "put";
      if (
        this.state.newExamData.email &&
        this.state.newExamData.token &&
        null !== this.state.newExamData.email &&
        null !== this.state.newExamData.token &&
        null !== this.state.newExamData.token
      ) {
        method = "patch";
      }
      let validExamData = finalExamData;
      let newQuestionsArray = [];
      validExamData.exam_questions.forEach((element) => {
        // delete element.correct_answer;
        // delete element.reason;
        let ele = {
          question_domain: element.question_domain,
          question_number: element.question_number,
          question: element.question,
          answer_choice: {
            a: element.answer_choice.a,
            b: element.answer_choice.b,
            c: element.answer_choice.c,
            d: element.answer_choice.d,
            e: element.answer_choice.e,
          },
          score: element.score,
          negative_score: element.negative_score,
          showStatus: element.showStatus,
          rich_text: element.rich_text,
          is_valid: element.is_valid,
        };
        newQuestionsArray.push(ele);
      });
      validExamData.exam_questions = newQuestionsArray;
      axios({
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/examstore/" + this.state.newExamData.exam_id,
        data: validExamData,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            let newExamData = this.state.newExamData;
            newExamData.exam_id = response.data.exam_id;
            newExamData.exam_code = response.data.exam_id;
            this.setState({ newExamData });
            console.log("Details updated successfully....");
            if (this.state.image) {
              this.uploadImage(response.data.exam_id);
            }
            if (
              !this.state.newExamData.token &&
              !this.state.newExamData.email
            ) {
              this.setState({ showAssistantStatus: true });
            } else {
              this.setState({ currentTab: 2 });
            }
            let final = this.state.isFinalSubmit;
            if (final === true) {
              this.setState({ RedirectNew: true });
            }
          } else {
            console.log("Error while saving details");
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.errors[0]?.message;

          // Show the standard error message from the response
          toast.error(errorMessage, {
            autoClose: 2000,
          });
        });
    }
  }

  onQuestionSubmit(type) {
    let currentQuestion = this.state.questionsList[this.state.currentQuestion];
    if (currentQuestion.score < currentQuestion.negative_score) {
      console.log("Negative score can not be more than question score.");
      return;
    }
    let finalExamData = this.state.newExamData;
    let questionsList = this.state.questionsList;
    finalExamData.exam_questions = [];
    finalExamData.answer_set = [];
    finalExamData.exam_status = "creating";
    if (finalExamData.total_exam_score)
      finalExamData.total_exam_score = parseInt(finalExamData.total_exam_score);
    if (finalExamData.pass_score)
      finalExamData.pass_score = parseInt(finalExamData.pass_score);

    if (finalExamData.number_of_questions)
      finalExamData.number_of_questions = parseInt(
        finalExamData.number_of_questions
      );
    // if (finalExamData.total_exam_score && finalExamData.pass_score) {
    //     finalExamData.pass_percent = (100 * finalExamData.pass_score) / finalExamData.total_exam_score;
    // }
    if (
      this.state.newExamData.negative_marking &&
      this.state.newExamData.negative_marking === "No"
    ) {
      finalExamData.neg_score_strategy = 0;
    }
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));
    if (instructorData && instructorData.user_id) {
      finalExamData.exam_creator_id = instructorData.user_id;
    }
    if (questionsList && questionsList.length > 0) {
      let i = 0;
      while (i < questionsList.length) {
        if (
          questionsList[i].question_domain &&
          questionsList[i].question_number &&
          questionsList[i].question &&
          questionsList[i].answer_choice &&
          questionsList[i].score &&
          questionsList[i].reason &&
          (questionsList[i].negative_score > 0 ||
            questionsList[i].negative_score === 0)
        ) {
          questionsList[i].question_number = parseInt(
            questionsList[i].question_number
          );
          questionsList[i].score = parseFloat(questionsList[i].score);
          questionsList[i].negative_score = parseFloat(
            questionsList[i].negative_score
          );
          if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "a"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.a;
            questionsList[i].correct_answer = "a";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "b"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.b;
            questionsList[i].correct_answer = "b";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "c"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.c;
            questionsList[i].correct_answer = "c";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "d"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.d;
            questionsList[i].correct_answer = "d";
          } else if (
            questionsList[i].correct_answer &&
            questionsList[i].correct_answer === "e"
          ) {
            // questionsList[i].correct_answer = questionsList[i].answer_choice.e;
            questionsList[i].correct_answer = "e";
          }
          finalExamData.exam_questions.push(questionsList[i]);
          let ans = {
            question_number: parseInt(questionsList[i].question_number),
            correct_answer: questionsList[i].correct_answer,
            reason: questionsList[i].reason,
            score: parseFloat(questionsList[i].score),
            negative_score: parseFloat(questionsList[i].negative_score),
          };
          finalExamData.answer_set.push(ans);
        }
        i++;
      }
    }
    if (
      finalExamData.exam_questions &&
      finalExamData.exam_questions.length > 0
    ) {
      let qLength = finalExamData.exam_questions.length;
      let progression =
        (100 * qLength) / this.state.newExamData.number_of_questions;
      finalExamData.progression = progression + "%";
    }
    let method = "put";
    if (
      this.state.newExamData.email &&
      this.state.newExamData.token &&
      null !== this.state.newExamData.email &&
      null !== this.state.newExamData.token &&
      null !== this.state.newExamData.token
    ) {
      method = "patch";
    }
    let validExamData = finalExamData;
    let newQuestionsArray = [];
    validExamData.exam_questions.forEach((element) => {
      // delete element.correct_answer;
      // delete element.reason;
      let ele = {
        question_domain: element.question_domain,
        question_number: element.question_number,
        question: element.question,
        answer_choice: {
          a: element.answer_choice.a,
          b: element.answer_choice.b,
          c: element.answer_choice.c,
          d: element.answer_choice.d,
          e: element.answer_choice.e,
        },
        score: element.score,
        negative_score: element.negative_score,
        showStatus: element.showStatus,
        rich_text: element.rich_text,
        is_valid: element.is_valid,
      };
      newQuestionsArray.push(ele);
    });
    validExamData.exam_questions = newQuestionsArray;
    axios({
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + this.state.newExamData.exam_id,
      data: validExamData,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let newExamData = this.state.newExamData;
          newExamData.exam_id = response.data.exam_id;
          newExamData.exam_code = response.data.exam_id;
          this.setState({ newExamData });
          if (type === "last") {
            this.setState({ currentQuestion: 0 });
            this.setState({ currentTab: 3 });
            let questionsList = this.state.questionsList;
            questionsList[questionsList.length - 1].showStatus = false;
            questionsList[questionsList.length - 1].is_valid = true;
            questionsList[0].showStatus = true;
            this.setState({ questionsList });
          } else {
            let questionsList = this.state.questionsList;
            let i = 0;
            let j = 0;
            if (questionsList) {
              while (i < questionsList.length) {
                if (i === this.state.currentQuestion) {
                  questionsList[i + 1].showStatus = true;
                  j = i + 1;
                  questionsList[i].showStatus = false;
                  questionsList[i].is_valid = true;
                }
                i++;
              }
              this.setState({ questionsList });
            }
            this.setState({ currentQuestion: j });
            // if (!this.state.questionsList[this.state.currentQuestion].correct_answer) {
            //     this.state.questionsList[this.state.currentQuestion].correct_answer = '';
            // }
          }
          console.log("Question details updated successfully....");
          return true;
        } else {
          console.log("Error while saving details");
          return false;
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
        return false;
      });
  }

  addRemoveForDeletion(item) {
    let list = this.state.deletionList;
    let indexItem = list.indexOf(item);
    indexItem === -1 ? list.push(item) : list.splice(item, 1);
    this.setState({ deletionList: list });
  }

  verifyQuestion(i) {
    if (!this.state.questionsList[i].question_domain) {
      // this.state.questionsList[i].question_domain = 'N.A.';
      let questionsList = this.state.questionsList;
      questionsList[i].question_domain = "N.A.";
      this.setState({ questionsList });
    }
    if (
      this.state.questionsList[i].question_domain &&
      this.state.questionsList[i].question_number &&
      this.state.questionsList[i].question &&
      this.state.questionsList[i].answer_choice &&
      this.state.questionsList[i].score &&
      this.state.questionsList[i].reason &&
      (this.state.questionsList[i].negative_score > 0 ||
        this.state.questionsList[i].negative_score === 0)
    ) {
      if (
        this.state.questionsList[i].correct_answer === "a" &&
        !this.state.questionsList[i].answer_choice["a"]
      ) {
        console.log(
          "Selected correct answer do not have value in option input, please correct"
        );
        return false;
      } else if (
        this.state.questionsList[i].correct_answer === "b" &&
        !this.state.questionsList[i].answer_choice["b"]
      ) {
        console.log(
          "Selected correct answer do not have value in option input, please correct"
        );
        return false;
      } else if (
        this.state.questionsList[i].correct_answer === "c" &&
        !this.state.questionsList[i].answer_choice["c"]
      ) {
        console.log(
          "Selected correct answer do not have value in option input, please correct"
        );
        return false;
      } else if (
        this.state.questionsList[i].correct_answer === "d" &&
        !this.state.questionsList[i].answer_choice["d"]
      ) {
        console.log(
          "Selected correct answer do not have value in option input, please correct"
        );
        return false;
      } else if (
        this.state.questionsList[i].correct_answer === "e" &&
        !this.state.questionsList[i].answer_choice["e"]
      ) {
        console.log(
          "Selected correct answer do not have value in option input, please correct"
        );
        return false;
      } else {
        return true;
      }
    } else {
      console.log("Fill all mandatory fields");
      return false;
    }
  }

  setImage(e) {
    this.setState({ image: e.target.files[0] });
  }

  uploadImage(exam_id) {
    let image = this.state.image;

    var bodyFormData = new FormData();
    bodyFormData.append("exam", image);
    // console.log(bodyFormData)
    axios({
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      url: "/api/images/exams/" + exam_id,
      data: bodyFormData,
    })
      .then(() => {
        console.log("image uploaded");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }
  myExams() {
    this.props.history.push("/instructor-exams");
  }

  createExam() {
    this.props.history.push("/createexam");
  }

  instructorlanding() {
    this.props.history.push("/instructorlanding");
  }

  // instructorlanding() {
  //     this.props.history.push('/instructorlanding')
  // }

  render() {
    if (this.state.RedirectNew === true) {
      return <Redirect to={{ pathname: "/instructor-exams" }} />;
    }
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <>
          <div className="container screen-padding-exs">
            {/* <div className="report--header">
                            <h3 className="text-center">Step-1: Create Exam MetaData</h3>

                        </div>                      */}

            <div
              id="home"
              className={
                "" + (this.state.currentTab === 1 ? "tabshow" : "tabhidden")
              }
            >
              {/* <div className="row m-1">
                                <div className="col-12 mt-3">
                                    <h4 className="text-center">Step-1: Create Exam MetaData</h4>
                                </div>
                            </div> */}
              <div className="report--header">
                <h3 className="text-center">Step-1: Create Exam MetaData</h3>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="container ExamData">
                    <div
                      className={
                        "" +
                        (this.state.showAssistantStatus === false
                          ? "tabshow"
                          : "tabhidden")
                      }
                    >
                      <div className="border">
                        <div className="form-group row">
                          <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                            <label
                              htmlFor="Exam Title"
                              className="col-12 col-form-label required"
                            >
                              Exam Title
                              {!this.state.newExamData.exam_title &&
                                this.state.is_submitted && (
                                  <span className="required-validation">
                                    Mandatory Field
                                  </span>
                                )}
                            </label>
                            <div className="col-12">
                              <input
                                type="text"
                                className="form-control"
                                id="Exam Title"
                                name="exam_title"
                                placeholder="Enter Your Exam Title"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "exam_title"
                                )}
                                defaultValue={
                                  this.state.newExamData["exam_title"] || ""
                                }
                              />
                              {/* <span className='text-danger'>{this.state.examTitleError}</span> */}
                            </div>
                          </div>
                          <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                            <label
                              htmlFor="inputExam SubTitle"
                              className="col-12 col-form-label required"
                            >
                              Exam SubTitle
                              {!this.state.newExamData.exam_subtitle &&
                                this.state.is_submitted && (
                                  <span className="required-validation">
                                    Mandatory Field
                                  </span>
                                )}
                            </label>
                            <div className="col-12">
                              <input
                                type="text"
                                className="form-control"
                                id="Exam SubTitle"
                                name="exam_subtitle"
                                placeholder="Enter Your Exam Title"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "exam_subtitle"
                                )}
                                defaultValue={
                                  this.state.newExamData["exam_subtitle"] || ""
                                }
                              />
                              {/* <span className='text-danger'>{this.state.examTitleError}</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="col-12 col-form-label required"
                          >
                            Exam Description
                            {!this.state.newExamData.exam_desc &&
                              this.state.is_submitted && (
                                <span className="required-validation">
                                  Mandatory Field
                                </span>
                              )}
                          </label>
                          <div className="col-md-12 col-sm-12 col-12">
                            {
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="2"
                                name="exam_desc"
                                placeholder="Enter Your Exam Title"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "exam_desc"
                                )}
                                defaultValue={
                                  this.state.newExamData["exam_desc"] || ""
                                }
                              ></textarea>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="border">
                        <div className="form-group row">
                          <div className="categories col-sm-12 col-xs-12 col-md-3 col-lg-6">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="col-form-label required"
                            >
                              Select Domain
                              {!this.state.newExamData.domain_id &&
                                this.state.is_submitted && (
                                  <span className="required-validation">
                                    Mandatory Field
                                  </span>
                                )}
                            </label>
                            <select
                              className="custom-select my-1 mr-sm-2"
                              id="inlineFormCustomSelectPref"
                              value={this.state.newExamData.domain_id}
                              onChange={this.examDataChange.bind(
                                this,
                                "domain_id"
                              )}
                              // defaultValue={this.state.newExamData["domain_id"] || ''}
                            >
                              <option value="">Select Domain</option>
                              {this.state.domainList.map((item, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={item.domain_id}
                                    className="hover_category"
                                  >
                                    {item.domain}
                                  </option>
                                );
                              }, this)}
                            </select>
                          </div>
                          <div className="categories col-sm-12 col-xs-12 col-md-3 col-lg-6">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="col-12 col-form-label required"
                            >
                              Select Category
                              {!this.state.newExamData.catagory_id &&
                                this.state.is_submitted && (
                                  <span className="required-validation">
                                    Mandatory Field
                                  </span>
                                )}
                            </label>
                            <select
                              className="custom-select my-1 mr-sm-2"
                              id="inlineFormCustomSelectPref"
                              value={this.state.newExamData.catagory_id}
                              onChange={this.examDataChange.bind(
                                this,
                                "catagory_id"
                              )}
                            >
                              <option value="">Select Category</option>
                              {this.state.categoryList.map((item, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={item.catagory_id}
                                    className="hover_category"
                                  >
                                    {item.catagory}
                                  </option>
                                );
                              }, this)}
                            </select>
                          </div>
                        </div>
                        <div className="radios row">
                          <div className="col-lg-2 col-md-2 col-sm-12">
                            <fieldset className="form-radio">
                              <legend className="col-form-label col-12 required">
                                Exam Mode
                                {!this.state.newExamData.exam_mode &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </legend>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exammodeRadios"
                                  id="exammodeRadios1"
                                  value="public"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_mode &&
                                    this.state.newExamData.exam_mode ===
                                      "public"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_mode"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exammodeRadios1"
                                >
                                  Public Exam
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exammodeRadios"
                                  id="exammodeRadios2"
                                  value="private"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_mode &&
                                    this.state.newExamData.exam_mode ===
                                      "private"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_mode"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exammodeRadios2"
                                >
                                  Private Exam
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exammodeRadios"
                                  id="exammodeRadios3"
                                  value="free"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_mode &&
                                    this.state.newExamData.exam_mode === "free"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_mode"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exammodeRadios3"
                                >
                                  Free
                                </label>
                              </div>
                            </fieldset>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12">
                            <fieldset className="form-radio">
                              <legend className="col-form-label col-12 required">
                                Exam Type
                                {!this.state.newExamData.exam_type &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </legend>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="examtypeRadios"
                                  id="examtypeRadios4"
                                  value="multiple_choice"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_type &&
                                    this.state.newExamData.exam_type ===
                                      "multiple_choice"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_type"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios4"
                                >
                                  MCQ
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="examtypeRadios"
                                  id="examtypeRadios5"
                                  value="descriptive"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_type &&
                                    this.state.newExamData.exam_type ===
                                      "descriptive"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_type"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios5"
                                >
                                  Generic
                                </label>
                              </div>
                            </fieldset>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12">
                            <fieldset className="form-radio">
                              <legend className="col-form-label col-12 required">
                                Study Mode
                                {!this.state.newExamData.study_mode &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </legend>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="studyModeRadios"
                                  id="examtypeRadios6"
                                  value="true"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.study_mode &&
                                    this.state.newExamData.study_mode === "true"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "study_mode"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios6"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="studyModeRadios"
                                  id="examtypeRadios7"
                                  value="false"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.study_mode &&
                                    this.state.newExamData.study_mode ===
                                      "false"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "study_mode"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios7"
                                >
                                  No
                                </label>
                              </div>
                            </fieldset>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12">
                            <fieldset className="form-radio">
                              <legend className="col-form-label col-12 required">
                                Exam Style
                                {!this.state.newExamData.exam_style &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </legend>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="examstyleRadios"
                                  id="examtypeRadios8"
                                  value="competitive"
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_style"
                                  )}
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_style &&
                                    this.state.newExamData.exam_style ===
                                      "competitive"
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios8"
                                >
                                  Competitive
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="examstyleRadios"
                                  id="examtypeRadios9"
                                  value="non-competitive"
                                  checked={
                                    this.state.newExamData &&
                                    this.state.newExamData.exam_style &&
                                    this.state.newExamData.exam_style ===
                                      "non-competitive"
                                  }
                                  onChange={this.examDataChange.bind(
                                    this,
                                    "exam_style"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios9"
                                >
                                  Non-Compet
                                </label>
                              </div>
                            </fieldset>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12">
                            <fieldset className="form-radio fieldset-custom">
                              <legend className="col-form-label col-12 required">
                                (-) Mark
                                {!this.state.newExamData.negative_marking &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </legend>
                              <div className="col-10">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="negativeMarking"
                                    id="examtypeRadios10"
                                    value="Yes"
                                    checked={
                                      this.state.newExamData &&
                                      this.state.newExamData.negative_marking &&
                                      this.state.newExamData
                                        .negative_marking === "Yes"
                                    }
                                    onChange={this.examDataChange.bind(
                                      this,
                                      "negative_marking"
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="examtypeRadios10"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="negativeMarking"
                                    id="examtypeRadios11"
                                    value="No"
                                    checked={
                                      this.state.newExamData &&
                                      this.state.newExamData.negative_marking &&
                                      this.state.newExamData
                                        .negative_marking === "No"
                                    }
                                    onChange={this.examDataChange.bind(
                                      this,
                                      "negative_marking"
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="examtypeRadios11"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div
                            className={
                              "col-lg-2 col-md-2 col-sm-12 " +
                              (this.state.newExamData.negative_marking === "Yes"
                                ? "navigationshow"
                                : "navigationhidden")
                            }
                          >
                            <fieldset className="form-radio">
                              <legend className="col-form-label col-12 required">
                                Negative %
                                {!this.state.newExamData.neg_score_strategy &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </legend>
                              <div className="col-10">
                                <div className="row exam-type">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="negativePercentage"
                                      id="examtypeRadios5"
                                      value="25"
                                      checked={
                                        this.state.newExamData &&
                                        this.state.newExamData
                                          .neg_score_strategy &&
                                        this.state.newExamData
                                          .neg_score_strategy === "25"
                                      }
                                      onChange={this.examDataChange.bind(
                                        this,
                                        "neg_score_strategy"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="examtypeRadios4"
                                    >
                                      25
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="negativePercentage"
                                      id="examtypeRadios5"
                                      value="33.33"
                                      checked={
                                        this.state.newExamData &&
                                        this.state.newExamData
                                          .neg_score_strategy &&
                                        this.state.newExamData
                                          .neg_score_strategy === "33.33"
                                      }
                                      onChange={this.examDataChange.bind(
                                        this,
                                        "neg_score_strategy"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="examtypeRadios4"
                                    >
                                      33.33
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>

                      <div className="border">
                        <div className="row">
                          <div className="col-xs-6 col-md-3 col-12">
                            <div>
                              <label
                                className="mr-sm-2 required"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Total Marks
                                {!this.state.newExamData.total_exam_score &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                min="0"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "total_exam_score"
                                )}
                                defaultValue={
                                  this.state.newExamData["total_exam_score"] ||
                                  ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xs-6 col-md-3 col-12">
                            <div>
                              <label
                                className="mr-sm-2"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Passing Marks
                                {!this.state.newExamData.pass_score &&
                                  this.state.newExamData.exam_style ===
                                    "non-competitive" &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field for Non-Competitive exams
                                    </span>
                                  )}
                              </label>
                              <input
                                type="number"
                                min="0"
                                className="form-control"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "pass_score"
                                )}
                                defaultValue={
                                  this.state.newExamData["pass_score"] || ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xs-6 col-md-3 col-12">
                            <div>
                              <label
                                className="mr-sm-2 required"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Total No. Questions
                                {!this.state.newExamData.number_of_questions &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                min="0"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "number_of_questions"
                                )}
                                defaultValue={
                                  this.state.newExamData[
                                    "number_of_questions"
                                  ] || ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xs-6 col-md-3 col-12">
                            <div>
                              <label
                                className="mr-sm-2 required"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Duration(In Mins)
                                {!this.state.newExamData.exam_duration &&
                                  this.state.is_submitted && (
                                    <span className="required-validation">
                                      Mandatory Field
                                    </span>
                                  )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                min="0"
                                onChange={this.examDataChange.bind(
                                  this,
                                  "exam_duration"
                                )}
                                defaultValue={
                                  this.state.newExamData["exam_duration"] || ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="mx-auto">
                          <button
                            style={{ float: "left" }}
                            className="btn btn-primary"
                            onClick={this.handleBackClick}
                          >
                            Back
                          </button>
                          <button
                            style={{ float: "right" }}
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              if (
                                this.state.newExamData.exam_title &&
                                this.state.newExamData.exam_subtitle &&
                                this.state.newExamData.exam_desc &&
                                this.state.newExamData.domain_id &&
                                this.state.newExamData.catagory_id &&
                                this.state.newExamData.exam_mode &&
                                this.state.newExamData.exam_style &&
                                this.state.newExamData.study_mode &&
                                this.state.newExamData.exam_type &&
                                ((this.state.newExamData.exam_mode &&
                                  this.state.newExamData.exam_style ===
                                    "non-competitive" &&
                                  this.state.newExamData.pass_score) ||
                                  this.state.newExamData.exam_style ===
                                    "competitive") &&
                                this.state.newExamData.total_exam_score &&
                                this.state.newExamData.number_of_questions &&
                                this.state.newExamData.exam_duration &&
                                this.state.newExamData.negative_marking
                              ) {
                                if (!this.state.newExamData.pass_score) {
                                  this.setState({ pass_score: 0 });
                                }
                                if (null != this.state.newExamData.exam_id) {
                                  let url;
                                  if (
                                    this.state.newExamData.email &&
                                    this.state.newExamData.token &&
                                    null !== this.state.newExamData.email &&
                                    null !== this.state.newExamData.token &&
                                    null !== this.state.newExamData.token
                                  ) {
                                    url =
                                      "/api/examstore/" +
                                      this.state.newExamData.exam_id +
                                      "/assistants?email=" +
                                      this.state.newExamData.email +
                                      "&token=" +
                                      this.state.newExamData.token;
                                  } else {
                                    url =
                                      "/api/examstore/" +
                                      this.state.newExamData.exam_id;
                                  }
                                  axios({
                                    method: "get",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    url: url,
                                  })
                                    .then((response) => {
                                      if (
                                        response.status === 200 ||
                                        response.status === 201
                                      ) {
                                        if (
                                          this.state.newExamData
                                            .number_of_questions <
                                          response.data.number_of_questions
                                        ) {
                                          this.setState({ deletionList: [] });
                                          this.showQuestionsChangesModal(
                                            response.data.number_of_questions
                                          );
                                        } else if (
                                          this.state.newExamData
                                            .number_of_questions >
                                          response.data.number_of_questions
                                        ) {
                                          let new_questions_count =
                                            this.state.newExamData
                                              .number_of_questions -
                                            response.data.number_of_questions;
                                          let questionsList =
                                            this.state.questionsList;
                                          if (!questionsList) {
                                            questionsList = [];
                                          }
                                          let prevLength =
                                            this.state.questionsList.length;
                                          let i = 0;
                                          while (i < new_questions_count) {
                                            let question = {
                                              question_domain: "",
                                              question_number:
                                                prevLength + i + 1,
                                              question: "",
                                              answer_choice: {
                                                a: "",
                                                b: "",
                                                c: "",
                                                d: "",
                                                e: "",
                                              },
                                              score: "",
                                              negative_score: "",
                                              showStatus: false,
                                              correct_answer: "",
                                              rich_text: false,
                                            };
                                            questionsList.push(question);
                                            i++;
                                          }
                                          this.setState({ questionsList });
                                          this.onExamFormSubmit();
                                          if (
                                            !this.state.newExamData.token &&
                                            !this.state.newExamData.email
                                          ) {
                                            this.setState({
                                              showAssistantStatus: true,
                                            });
                                          } else {
                                            this.setState({ currentTab: 2 });
                                          }
                                        } else {
                                          this.onExamFormSubmit();
                                          if (
                                            !this.state.newExamData.token &&
                                            !this.state.newExamData.email
                                          ) {
                                            this.setState({
                                              showAssistantStatus: true,
                                            });
                                          } else {
                                            this.setState({ currentTab: 2 });
                                          }
                                        }
                                      } else {
                                        console.log("Details not found");
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(
                                        error.response.data.errors[0].message
                                      );
                                    });
                                } else {
                                  this.onExamFormSubmit();
                                }
                              } else {
                                this.setState({ is_submitted: true });
                                console.log(
                                  "Please fill all mandatory fileds to continue"
                                );
                              }
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        "" +
                        (this.state.showAssistantStatus === true
                          ? "tabshow"
                          : "tabhidden")
                      }
                    >
                      <div className="border">
                        <div
                          className={
                            "row " +
                            (!this.state.newExamData.token &&
                            !this.state.newExamData.email
                              ? "m5show"
                              : "m5hidden")
                          }
                        >
                          <div className="col-12">
                            <h4>Assistants:</h4>
                            <h6>
                              (You can add up to 3 assistants email ids, on
                              adding email id as assistant, he/she can edit this
                              exam details untill the exam is in creating mode.)
                            </h6>
                            <div className="row text-center">
                              <div className="col-1"></div>
                              <div className="col-10 table-responsive">
                                <table className="table table-bordered table-sm">
                                  <thead>
                                    <tr>
                                      <th scope="col">Sr. No.</th>
                                      <th scope="col">Email</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Change Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.assistants.map((item, i) => (
                                      <tr key={i + "assistants"}>
                                        <th scope="row ">{i + 1}</th>
                                        <td>{item.email}</td>
                                        <td>{item.validity}</td>
                                        <td>
                                          <tr className="table-responsive text-wk-center">
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  this.changeAssistantStatus(
                                                    item.email,
                                                    "delete",
                                                    "disabled"
                                                  );
                                                }}
                                              >
                                                Disable
                                              </button>
                                            </td>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  this.changeAssistantStatus(
                                                    item.email,
                                                    "patch",
                                                    "suspended"
                                                  );
                                                }}
                                              >
                                                Suspend
                                              </button>
                                            </td>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  this.changeAssistantStatus(
                                                    item.email,
                                                    "put",
                                                    "activated"
                                                  );
                                                }}
                                              >
                                                Activate
                                              </button>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-1"></div>
                            </div>
                            <br />
                            <br />
                            <div
                              className={
                                "row " +
                                (this.state.assistants.length < 3
                                  ? "m5show"
                                  : "m5hidden")
                              }
                            >
                              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
                              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 input-group flex-nowrap mb-4">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="addon-wrapping"
                                  >
                                    Email-
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter assistant Email"
                                  aria-label="Instruction"
                                  aria-describedby="addon-wrapping"
                                  onChange={this.instructionDataChange.bind(
                                    this,
                                    "assistantEmail"
                                  )}
                                />
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    if (this.state.assistantsEmail) {
                                      let data = {
                                        email: [],
                                      };
                                      data.email.push(
                                        this.state.assistantsEmail
                                      );
                                      axios({
                                        method: "put",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        url:
                                          "/api/examstore/" +
                                          this.state.newExamData.exam_id +
                                          "/assistants",
                                        data: data,
                                      })
                                        .then((response) => {
                                          if (
                                            response.status === 201 ||
                                            response.status === 200
                                          ) {
                                            this.getAssistantsList(
                                              this.state.newExamData.exam_id
                                            );
                                            let assistantsEmail = "";
                                            this.setState({ assistantsEmail });
                                            console.log(
                                              "Success-Token is shared with the provided email id"
                                            );
                                          }
                                        })
                                        .catch((error) => {
                                          console.log(
                                            error.response.data.errors[0]
                                              .message
                                          );
                                        });
                                    } else {
                                      console.log("Enter valid email id");
                                    }
                                  }}
                                >
                                  Add Assistants
                                </button>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
                            </div>
                            <br />
                            <div className="row">
                              {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div> */}
                              <div className="mx-auto">
                                <button
                                  type="button"
                                  className={
                                    "btn btn-primary btn-sm" +
                                    (this.state.assistants.length === 0
                                      ? "m5show"
                                      : "m5hidden")
                                  }
                                  onClick={() => {
                                    this.setState({
                                      showAssistantStatus: false,
                                    });
                                    this.setState({ currentTab: 2 });
                                  }}
                                >
                                  Skip And Continue
                                </button>
                                {
                                  <button
                                    type="button"
                                    className={
                                      "btn btn-primary btn-sm mt-2 " +
                                      (this.state.assistants.length > 0
                                        ? "m5show"
                                        : "m5hidden")
                                    }
                                    onClick={() => {
                                      this.setState({
                                        showAssistantStatus: false,
                                      });
                                      this.setState({ currentTab: 2 });
                                    }}
                                  >
                                    Continue
                                  </button>
                                }
                              </div>
                              {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div> */}
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div
              id="menu1"
              className={
                "" + (this.state.currentTab === 2 ? "tabshow" : "tabhidden")
              }
            >
              <div className="report--header">
                <h3 className="text-center">Step-2: Exam Question Details</h3>
              </div>

              <div
                className={
                  "row mt-4 " +
                  (this.state.questionsList.length > 0
                    ? "navigationshow"
                    : "navigationhidden")
                }
                key="1"
              >
                <div className="col-12" key="2">
                  <h6 className="text-center">Navigate to Question:</h6>
                  <div className="row questions-pallet border" key="3">
                    {this.state.questionsList.map((item) => (
                      <div
                        className="col-1 text-center MARGINbOX"
                        key={item.question_number}
                        onClick={() => {
                          if (this.state.questionsList) {
                            // this.state.questionsList[this.state.currentQuestion].showStatus = false;
                            // this.state.questionsList[item.question_number - 1].showStatus = true;
                            let questionsList = this.state.questionsList;
                            questionsList[
                              this.state.currentQuestion
                            ].showStatus = false;
                            questionsList[
                              item.question_number - 1
                            ].showStatus = true;
                            this.setState({ questionsList });
                          }
                          this.setState({
                            currentQuestion: item.question_number - 1,
                          });
                        }}
                      >
                        <div
                          className={
                            "rounded-circle question-number " +
                            (item.is_valid === true
                              ? "valid_question"
                              : "invalid_question")
                          }
                        >
                          {item.question_number}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  {this.state.questionsList.map((item) => (
                    <div
                      className={
                        "border " +
                        (item.showStatus === true ? "show" : "hidden")
                      }
                      key={item.question_number}
                    >
                      <div className="row text-center">
                        <div className="col-xs-12 col-md-12 col-12">
                          <div>
                            <label
                              className="mr-2 font-weight-bold"
                              htmlFor="inlineFormCustomSelect"
                            >
                              Question No. {item.question_number} of{" "}
                              {this.state.questionsList.length}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-xs-6 col-md-3 col-12">
                                                <div>
                                                    <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">Type</label>
                                                    <select className="custom-select mr-sm-2"
                                                        id="inlineFormCustomSelect" name="question_type"
                                                        onChange={this.questionDataChange.bind(this, "question_type")}
                                                    >
                                                        <option value=''>Choose...</option>
                                                        <option value='Mcq'>Mcq</option>
                                                        <option value='Text'>Text</option>
                                                    </select>
                                                </div>
                                            </div> */}
                        <div className="col-xs-6 col-md-3 col-12">
                          <div>
                            <label
                              className="ml-4"
                              htmlFor="inlineFormCustomSelect"
                            >
                              View Type:
                            </label>
                            {/* <select className="custom-select mr-sm-2"
                                                            id="inlineFormCustomSelect" name="rich_text"
                                                            onChange={this.questionDataChange.bind(this, "rich_text")}
                                                        >
                                                            <option value={'false'}>Simple</option>
                                                            <option value={'true'}>Rich Text View</option>
                                                        </select> */}
                            <div className="form-check form-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`rich_text-${item.question_number}`}
                                id={`examtypeRadios10-${item.question_number}`}
                                value="false"
                                checked={
                                  item.rich_text === false ||
                                  item.rich_text === "false"
                                }
                                onChange={this.questionDataChange.bind(
                                  this,
                                  "rich_text"
                                )}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="examtypeRadios4"
                              >
                                Simple
                              </label>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`rich_text-${item.question_number}`}
                                  id={`examtypeRadios10-${item.question_number}`}
                                  value="true"
                                  checked={
                                    item.rich_text === true ||
                                    item.rich_text === "true"
                                  }
                                  onChange={this.questionDataChange.bind(
                                    this,
                                    "rich_text"
                                  )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="examtypeRadios4"
                                >
                                  Rich text
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-12">
                          <div>
                            <label
                              className="mr-sm-2"
                              htmlFor="inlineFormCustomSelect"
                            >
                              Domain/Syllabus{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="question_domain"
                              value={
                                this.state.questionsList[
                                  this.state.currentQuestion
                                ].question_domain
                              }
                              onChange={this.questionDataChange.bind(
                                this,
                                "question_domain"
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-xs-12 col-md-4 col-12">
                          <div>
                            <label
                              className="mr-sm-2 required"
                              htmlFor="inlineFormCustomSelect"
                            >
                              Marks
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="score"
                              value={
                                this.state.questionsList[
                                  this.state.currentQuestion
                                ].score
                              }
                              onChange={this.questionDataChange.bind(
                                this,
                                "score"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      {!item.rich_text ||
                      item.rich_text === "" ||
                      item.rich_text === false ||
                      item.rich_text === "false" ? (
                        <div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "question"}
                          >
                            <div className="col-xs-12 col-md-12 col-12">
                              <div>
                                <label
                                  className="mr-sm-2 required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  Question
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows=""
                                  name="question"
                                  placeholder="Enter question"
                                  onChange={this.questionDataChange.bind(
                                    this,
                                    "question"
                                  )}
                                  value={
                                    this.state.questionsList[
                                      this.state.currentQuestion
                                    ].question || ""
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <form className="row mt-4">
                            <div
                              className="form-group col-xs-6 col-md-6 col-12"
                              id={item.question_number + "optiona"}
                            >
                              <div className="form-row">
                                <label
                                  className="col-auto col-form-label required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  A.
                                </label>

                                <div className="col">
                                  <input
                                    type="text"
                                    className="col form-control"
                                    name="a"
                                    value={
                                      this.state.questionsList[
                                        this.state.currentQuestion
                                      ].answer_choice.a
                                    }
                                    onChange={this.questionDataChange.bind(
                                      this,
                                      "a"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="form-group col-xs-6 col-md-6 col-12"
                              id={item.question_number + "optionb"}
                            >
                              <div className="form-row">
                                <label
                                  className="col-auto col-form-label required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  B.
                                </label>
                                <div className="col">
                                  <input
                                    type="text"
                                    className="col form-control"
                                    name="b"
                                    value={
                                      this.state.questionsList[
                                        this.state.currentQuestion
                                      ].answer_choice.b
                                    }
                                    onChange={this.questionDataChange.bind(
                                      this,
                                      "b"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="form-group col-xs-6 col-md-6 col-12"
                              id={item.question_number + "optionc"}
                            >
                              <div className="form-row">
                                <label
                                  className="col-auto col-form-label"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  C.*
                                </label>
                                <div className="col">
                                  <input
                                    type="text"
                                    className="col form-control"
                                    name="c"
                                    value={
                                      this.state.questionsList[
                                        this.state.currentQuestion
                                      ].answer_choice.c
                                    }
                                    onChange={this.questionDataChange.bind(
                                      this,
                                      "c"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="form-group col-xs-6 col-md-6 col-12"
                              id={item.question_number + "optiond"}
                            >
                              <div className="form-row">
                                <label
                                  className="col-auto col-form-label"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  D.*
                                </label>
                                <div className="col">
                                  <input
                                    type="text"
                                    className="col form-control"
                                    name="d"
                                    value={
                                      this.state.questionsList[
                                        this.state.currentQuestion
                                      ].answer_choice.d
                                    }
                                    onChange={this.questionDataChange.bind(
                                      this,
                                      "d"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="form-group col-xs-6 col-md-6 col-12"
                              id={item.question_number + "optione"}
                            >
                              <div className="form-row">
                                <label
                                  className="col-auto col-form-label"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  E.*
                                </label>
                                <div className="col">
                                  <input
                                    type="text"
                                    className="col form-control"
                                    name="e"
                                    value={
                                      this.state.questionsList[
                                        this.state.currentQuestion
                                      ].answer_choice.e
                                    }
                                    onChange={this.questionDataChange.bind(
                                      this,
                                      "e"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="row mt-4">
                            <div className="col-xs-6 col-md-6 col-12">
                              <div>
                                <label
                                  className=" required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  Correct Answer
                                </label>
                                <select
                                  className="custom-select col-xs-6 col-md-12 col-12"
                                  id="inlineFormCustomSelect"
                                  name="correct_answer"
                                  value={
                                    this.state.questionsList[
                                      this.state.currentQuestion
                                    ].correct_answer
                                  }
                                  onChange={this.questionDataChange.bind(
                                    this,
                                    "correct_answer"
                                  )}
                                >
                                  <option value="">Select</option>
                                  <option value={"a"}>A</option>
                                  <option value={"b"}>B</option>
                                  <option value={"c"}>C</option>
                                  <option value={"d"}>D</option>
                                  <option value={"e"}>E</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xs-6 col-md-6 col-12">
                              <div>
                                <label
                                  className=" required "
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  Negative Marks
                                </label>
                                <input
                                  type="number"
                                  className=" col-xs-6 col-md-12 col-12  form-control"
                                  name="negative_score"
                                  value={item.negative_score}
                                  onChange={this.questionDataChange.bind(
                                    this,
                                    "negative_score"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "answer"}
                          >
                            <div className="col-xs-12 col-md-12 col-12">
                              <div>
                                <label
                                  className="mr-sm-2 required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  Explain Answer
                                </label>
                                {/* <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  name="reason"
                                  placeholder="Enter explanation for answer"
                                  onChange={this.questionDataChange.bind(
                                    this,
                                    "reason"
                                  )}
                                  value={item.reason || ""}
                                ></textarea> */}
                                <div className="col-12 summernote">
                                  <ReactSummernote
                                    value={this.state.defaultTextSummerNote}
                                    onInit={() => {
                                      var x = document
                                        .getElementById(
                                          item.question_number + "answer"
                                        )
                                        .querySelectorAll(".note-editable");
                                      if (x && x[0]) {
                                        if (item.reason) {
                                          x[0].innerHTML = item.reason;
                                        }
                                      }
                                    }}
                                    options={{
                                      lang: "en-US",
                                      height: 150,
                                      dialogsInBody: true,
                                      toolbar: [
                                        ["style", ["style"]],
                                        [
                                          "font",
                                          ["bold", "underline", "clear"],
                                        ],
                                        ["fontname", ["fontname"]],
                                        ["para", ["ul", "ol", "paragraph"]],
                                        ["table", ["table"]],
                                        ["insert", ["link", "picture"]],
                                        ["view", ["fullscreen"]],
                                      ],
                                    }}
                                    onChange={(content) => {
                                      let questionsList =
                                        this.state.questionsList;
                                      questionsList[this.state.currentQuestion][
                                        "reason"
                                      ] = content;
                                      this.setState({ questionsList });
                                    }}
                                    onImageUpload={this.onImageUpload}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "question"}
                          >
                            <div className="col-xs-6 col-md-12 col-12">
                              <div className="form-group">
                                <label
                                  className="mr-sm-2 required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  Question
                                </label>
                                <div className="col-12">
                                  <ReactSummernote
                                    onInit={() => {
                                      var x = document
                                        .getElementById(
                                          item.question_number + "question"
                                        )
                                        .querySelectorAll(".note-editable");
                                      if (x && x[0]) {
                                        if (item.question) {
                                          x[0].innerHTML = item.question;
                                        }
                                      }
                                    }}
                                    options={{
                                      lang: "en-US",
                                      height: 150,
                                      dialogsInBody: true,
                                      maximumImageFileSize: 100 * 1024, // 500 KB
                                      toolbar: [
                                        ["undo", ["undo"]],
                                        ["redo", ["redo"]],
                                        ["style", ["style"]],
                                        [
                                          "font",
                                          ["bold", "underline", "clear"],
                                        ],
                                        ["fontname", ["fontname"]],
                                        ["table", ["table"]],
                                        ["insert", ["link", "picture"]],
                                        ["view", ["fullscreen"]],
                                        // ['color', ['color']],
                                        ["font", ["strikethrough"]],
                                        ["fontsize", ["fontsize"]],
                                        ["para", ["ul", "ol", "paragraph"]],
                                      ],
                                      focus: true,
                                      fontSizes: [
                                        "8",
                                        "9",
                                        "10",
                                        "11",
                                        "12",
                                        "14",
                                        "18",
                                        "22",
                                        "24",
                                        "36",
                                        "48",
                                        "64",
                                        "82",
                                        "150",
                                      ],
                                    }}
                                    onChange={(content) => {
                                      let questionsList =
                                        this.state.questionsList;
                                      questionsList[this.state.currentQuestion][
                                        "question"
                                      ] = content;
                                      this.setState({ questionsList });
                                    }}
                                    onImageUpload={this.onImageUpload}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "optiona"}
                          >
                            <div className="col-xs-6 col-md-1 col-12">
                              <h6 className="required">A.</h6>
                            </div>
                            <div className="col-xs-6 col-md-10 col-12">
                              <div>
                                <ReactSummernote
                                  onInit={() => {
                                    let qn = item.question_number;
                                    var x = document
                                      .getElementById(
                                        item.question_number + "optiona"
                                      )
                                      .querySelectorAll(".note-editable");
                                    if (x && x[0]) {
                                      if (
                                        this.state.questionsList[qn - 1]
                                          .answer_choice.a
                                      ) {
                                        x[0].innerHTML =
                                          this.state.questionsList[
                                            qn - 1
                                          ].answer_choice.a;
                                      }
                                    }
                                  }}
                                  options={{
                                    lang: "en-US",
                                    height: 50,
                                    dialogsInBody: true,
                                    toolbar: [
                                      ["style", ["style"]],
                                      ["font", ["bold", "underline", "clear"]],
                                      ["fontname", ["fontname"]],
                                      ["para", ["ul", "ol", "paragraph"]],
                                      ["table", ["table"]],
                                      ["insert", ["link", "picture"]],
                                      ["view", ["fullscreen"]],
                                    ],
                                  }}
                                  onChange={(content) => {
                                    let i = 0;
                                    let field = "a";
                                    let qn = item.question_number;
                                    let questionsList =
                                      this.state.questionsList;
                                    while (
                                      i < this.state.questionsList.length
                                    ) {
                                      if (i === qn - 1) {
                                        questionsList[i]["answer_choice"][
                                          field
                                        ] = content;
                                      }
                                      i++;
                                    }
                                    this.setState({ questionsList });
                                  }}
                                  onImageUpload={this.onImageUpload}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "optionb"}
                          >
                            <div className="col-xs-6 col-md-1 col-12">
                              <h6 className="required">B.</h6>
                            </div>
                            <div className="col-xs-6 col-md-10 col-12">
                              <div>
                                {/* <input type="text" className="form-control"  name="b"
                                                            value={this.state.questionsList[this.state.currentQuestion].answer_choice.b}
                                                            onChange={this.questionDataChange.bind(this, "b")} /> */}
                                <ReactSummernote
                                  onInit={() => {
                                    let qn = item.question_number;
                                    var x = document
                                      .getElementById(
                                        item.question_number + "optionb"
                                      )
                                      .querySelectorAll(".note-editable");
                                    if (x && x[0]) {
                                      if (
                                        this.state.questionsList[qn - 1]
                                          .answer_choice.b
                                      ) {
                                        x[0].innerHTML =
                                          this.state.questionsList[
                                            qn - 1
                                          ].answer_choice.b;
                                      }
                                    }
                                  }}
                                  options={{
                                    lang: "en-US",
                                    height: 50,
                                    dialogsInBody: true,
                                    toolbar: [
                                      ["style", ["style"]],
                                      ["font", ["bold", "underline", "clear"]],
                                      ["fontname", ["fontname"]],
                                      ["para", ["ul", "ol", "paragraph"]],
                                      ["table", ["table"]],
                                      ["insert", ["link", "picture"]],
                                      ["view", ["fullscreen"]],
                                    ],
                                  }}
                                  onChange={(content) => {
                                    let i = 0;
                                    let field = "b";
                                    let qn = item.question_number;
                                    let questionsList =
                                      this.state.questionsList;
                                    while (
                                      i < this.state.questionsList.length
                                    ) {
                                      if (i === qn - 1) {
                                        questionsList[i]["answer_choice"][
                                          field
                                        ] = content;
                                      }
                                      i++;
                                    }
                                    this.setState({ questionsList });
                                  }}
                                  onImageUpload={this.onImageUpload}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "optionc"}
                          >
                            <div className="col-xs-6 col-md-1 col-12">
                              <h6>C.</h6>
                            </div>
                            <div className="col-xs-6 col-md-10 col-12">
                              <div>
                                {/* <input type="text" className="form-control"  name="a"
                                                            value={this.state.questionsList[this.state.currentQuestion].answer_choice.c}
                                                            onChange={this.questionDataChange.bind(this, "c")} /> */}
                                <ReactSummernote
                                  onInit={() => {
                                    let qn = item.question_number;
                                    var x = document
                                      .getElementById(
                                        item.question_number + "optionc"
                                      )
                                      .querySelectorAll(".note-editable");
                                    if (x && x[0]) {
                                      if (
                                        this.state.questionsList[qn - 1]
                                          .answer_choice.c
                                      ) {
                                        x[0].innerHTML =
                                          this.state.questionsList[
                                            qn - 1
                                          ].answer_choice.c;
                                      }
                                    }
                                  }}
                                  options={{
                                    lang: "en-US",
                                    height: 50,
                                    dialogsInBody: true,
                                    toolbar: [
                                      ["style", ["style"]],
                                      ["font", ["bold", "underline", "clear"]],
                                      ["fontname", ["fontname"]],
                                      ["para", ["ul", "ol", "paragraph"]],
                                      ["table", ["table"]],
                                      ["insert", ["link", "picture"]],
                                      ["view", ["fullscreen"]],
                                    ],
                                  }}
                                  onChange={(content) => {
                                    let i = 0;
                                    let field = "c";
                                    let qn = item.question_number;
                                    let questionsList =
                                      this.state.questionsList;
                                    while (
                                      i < this.state.questionsList.length
                                    ) {
                                      if (i === qn - 1) {
                                        questionsList[i]["answer_choice"][
                                          field
                                        ] = content;
                                      }
                                      i++;
                                    }
                                    this.setState({ questionsList });
                                  }}
                                  onImageUpload={this.onImageUpload}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "optiond"}
                          >
                            <div className="col-xs-6 col-md-1 col-12">
                              <h6>D.</h6>
                            </div>
                            <div className="col-xs-6 col-md-10 col-12">
                              <div>
                                {/* <input type="text" className="form-control"  name="d"
                                                            value={this.state.questionsList[this.state.currentQuestion].answer_choice.d}
                                                            onChange={this.questionDataChange.bind(this, "d")} /> */}
                                <ReactSummernote
                                  onInit={() => {
                                    let qn = item.question_number;
                                    var x = document
                                      .getElementById(
                                        item.question_number + "optiond"
                                      )
                                      .querySelectorAll(".note-editable");
                                    if (x && x[0]) {
                                      if (
                                        this.state.questionsList[qn - 1]
                                          .answer_choice.d
                                      ) {
                                        x[0].innerHTML =
                                          this.state.questionsList[
                                            qn - 1
                                          ].answer_choice.d;
                                      }
                                    }
                                  }}
                                  options={{
                                    lang: "en-US",
                                    height: 50,
                                    dialogsInBody: true,
                                    toolbar: [
                                      ["style", ["style"]],
                                      ["font", ["bold", "underline", "clear"]],
                                      ["fontname", ["fontname"]],
                                      ["para", ["ul", "ol", "paragraph"]],
                                      ["table", ["table"]],
                                      ["insert", ["link", "picture"]],
                                      ["view", ["fullscreen"]],
                                    ],
                                  }}
                                  onChange={(content) => {
                                    let i = 0;
                                    let field = "d";
                                    let qn = item.question_number;
                                    let questionsList =
                                      this.state.questionsList;
                                    while (
                                      i < this.state.questionsList.length
                                    ) {
                                      if (i === qn - 1) {
                                        questionsList[i]["answer_choice"][
                                          field
                                        ] = content;
                                      }
                                      i++;
                                    }
                                    this.setState({ questionsList });
                                  }}
                                  onImageUpload={this.onImageUpload}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "optione"}
                          >
                            <div className="col-xs-6 col-md-1 col-12">
                              <h6>E.</h6>
                            </div>
                            <div className="col-xs-6 col-md-10 col-12">
                              <div>
                                {/* <input type="text" className="form-control"  name="e"
                                                            value={this.state.questionsList[this.state.currentQuestion].answer_choice.e}
                                                            onChange={this.questionDataChange.bind(this, "e")} /> */}
                                <ReactSummernote
                                  onInit={() => {
                                    let qn = item.question_number;
                                    var x = document
                                      .getElementById(
                                        item.question_number + "optione"
                                      )
                                      .querySelectorAll(".note-editable");
                                    if (x && x[0]) {
                                      if (
                                        this.state.questionsList[qn - 1]
                                          .answer_choice.e
                                      ) {
                                        x[0].innerHTML =
                                          this.state.questionsList[
                                            qn - 1
                                          ].answer_choice.e;
                                      }
                                    }
                                  }}
                                  options={{
                                    lang: "en-US",
                                    height: 50,
                                    dialogsInBody: true,
                                    toolbar: [
                                      ["style", ["style"]],
                                      ["font", ["bold", "underline", "clear"]],
                                      ["fontname", ["fontname"]],
                                      ["para", ["ul", "ol", "paragraph"]],
                                      ["table", ["table"]],
                                      ["insert", ["link", "picture"]],
                                      ["view", ["fullscreen"]],
                                    ],
                                  }}
                                  onChange={(content) => {
                                    let i = 0;
                                    let field = "e";
                                    let qn = item.question_number;
                                    let questionsList =
                                      this.state.questionsList;
                                    while (
                                      i < this.state.questionsList.length
                                    ) {
                                      if (i === qn - 1) {
                                        questionsList[i]["answer_choice"][
                                          field
                                        ] = content;
                                      }
                                      i++;
                                    }
                                    this.setState({ questionsList });
                                  }}
                                  onImageUpload={this.onImageUpload}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-xs-6 col-md-4 col-12">
                              <div>
                                <label
                                  className="mr-sm-2 required"
                                  htmlFor="inlineFormCustomSelect"
                                >
                                  Correct Answer
                                </label>
                                <select
                                  className="custom-select mr-sm-1"
                                  id="inlineFormCustomSelect"
                                  name="correct_answer"
                                  value={
                                    this.state.questionsList[
                                      this.state.currentQuestion
                                    ].correct_answer
                                  }
                                  onChange={this.questionDataChange.bind(
                                    this,
                                    "correct_answer"
                                  )}
                                >
                                  <option value="">Select</option>
                                  <option value={"a"}>A</option>
                                  <option value={"b"}>B</option>
                                  <option value={"c"}>C</option>
                                  <option value={"d"}>D</option>
                                  <option value={"e"}>E</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xs-6 col-md-4 col-12">
                              <label
                                className="mr-sm-2 required"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Negative Marks
                              </label>
                              <input
                                type="number"
                                className=" form-control mr-sm-1"
                                name="negative_score"
                                value={item.negative_score}
                                onChange={this.questionDataChange.bind(
                                  this,
                                  "negative_score"
                                )}
                              />
                            </div>
                          </div>
                          <div
                            className="row mt-4"
                            id={item.question_number + "answer"}
                          >
                            {/* <div className="col-xs-12 col-md-12 col-12">
                              <div className="form-group">
                                <label className="required">
                                  {" "}
                                  Explain Answer
                                </label>
                                <div className="col-12">
                                  <ReactSummernote
                                    onInit={() => {
                                      var x = document
                                        .getElementById(
                                          item.question_number + "answer"
                                        )
                                        .querySelectorAll(".note-editable");
                                      if (x && x[0]) {
                                        if (item.reason) {
                                          x[0].innerHTML = item.reason;
                                        }
                                      }
                                    }}
                                    options={{
                                      lang: "en-US",
                                      height: 150,
                                      dialogsInBody: true,
                                      toolbar: [
                                        ["style", ["style"]],
                                        [
                                          "font",
                                          ["bold", "underline", "clear"],
                                        ],
                                        ["fontname", ["fontname"]],
                                        ["para", ["ul", "ol", "paragraph"]],
                                        ["table", ["table"]],
                                        ["insert", ["link", "picture"]],
                                        ["view", ["fullscreen"]],
                                      ],
                                    }}
                                    onChange={(content) => {
                                      let questionsList =
                                        this.state.questionsList;
                                      questionsList[this.state.currentQuestion][
                                        "reason"
                                      ] = content;
                                      this.setState({ questionsList });
                                    }}
                                    onImageUpload={this.onImageUpload}
                                  />
                                </div>
                              </div>
                            </div> */}
                            <div className="col-xs-6 col-md-1 col-12"></div>
                          </div>
                        </div>
                      )}

                      {/* <div className={"" + ((!item.rich_text || item.rich_text === '' || item.rich_text === false || item.rich_text === 'false') ? 'tabshow' : 'tabhidden')}>

                                            </div>
                                            <div className={"" + ((item.rich_text && (item.rich_text === true || item.rich_text === 'true')) ? 'tabshow' : 'tabhidden')}>

                                            </div> */}

                      <div className="form-group row">
                        <div className="col-md-5 col-sm-6 text-left">
                          <button
                            type="button"
                            className={
                              "btn btn-primary btn-sm btn-float-left btn-save " +
                              (this.state.currentQuestion > 0
                                ? "prevnextshow"
                                : "prevnexthidden")
                            }
                            onClick={() => {
                              let questionsList = this.state.questionsList;
                              let i = 0;
                              let j = 0;
                              if (questionsList) {
                                while (i < questionsList.length) {
                                  if (i === this.state.currentQuestion) {
                                    questionsList[i - 1].showStatus = true;
                                    j = i - 1;
                                    questionsList[i].showStatus = false;
                                  }
                                  i++;
                                }
                                this.setState({ questionsList });
                              }
                              this.setState({ currentQuestion: j });
                            }}
                          >
                            Previous Q
                          </button>
                        </div>
                        <div className="col-md-2 col-sm-6 text-center">
                          <button
                            type="button"
                            className={
                              "btn btn-primary btn-sm btn-save " +
                              (this.state.currentQuestion <
                              this.state.questionsList.length - 1
                                ? "prevnextshow"
                                : "prevnexthidden")
                            }
                            onClick={() => {
                              if (
                                this.verifyQuestion(this.state.currentQuestion)
                              ) {
                                this.onQuestionSubmit("notlast");
                              }
                            }}
                          >
                            {" "}
                            Next Question
                          </button>
                        </div>
                        <div className="col-md-5 col-sm-6 text-right">
                          <button
                            type="button"
                            className={
                              "btn btn-primary btn-sm btn-float-right btn-save " +
                              (this.state.currentQuestion ===
                              this.state.questionsList.length - 1
                                ? "prevnextshow"
                                : "prevnexthidden")
                            }
                            onClick={() => {
                              if (
                                this.props.history.push("/instructor-exams")
                              ) {
                                this.onQuestionSubmit("last");
                              }
                            }}
                          >
                            Save and Continue later
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="form-group row">
                    <div className="col-md-5 col-sm-6 text-left">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm btn-save"
                        onClick={() => {
                          this.setState({ currentTab: 1 });
                        }}
                      >
                        Previous Step
                      </button>
                    </div>
                    <div className="col-md-2 col-sm-6 text-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                        onClick={() => {
                          console.log(this.state.newExamData);
                        }}
                      >
                        Preview
                      </button>
                    </div>
                    <div className="col-md-5 col-sm-6 text-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm btn-save"
                        onClick={() => {
                          let questionsList = this.state.questionsList;
                          let i = 0;
                          let j = 0;
                          if (questionsList) {
                            while (i < questionsList.length) {
                              if (this.verifyQuestion(i)) {
                                questionsList[i].is_valid = true;
                              } else {
                                questionsList[i].is_valid = false;
                                j++;
                              }
                              i++;
                            }
                            this.setState({ questionsList });
                            if (j > 0) {
                              console.log(
                                "Fill all question details to continue"
                              );
                            } else {
                              this.setState({ currentTab: 3 });
                            }
                          }
                        }}
                      >
                        {" "}
                        Save and Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="menu2"
              className={
                "" + (this.state.currentTab === 3 ? "tabshow" : "tabhidden")
              }
            >
              {/* <div className="row m-1">
                                <div className="col-12">
                                    <h4 className="text-center mt-3">Exam Creation Step-3:Instruction and Other Details</h4>
                                </div>
                            </div> */}
              <div className="report--header">
                <h3 className="text-center">
                  Step-3: Instructions and Other Details
                </h3>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="border text-left">
                    <div className="row">
                      <div className="col-12">
                        <h4>Exam Instructions:</h4>
                        <div className="input-group flex-nowrap mb-4">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                            >
                              1
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instruction 1"
                            aria-label="Instruction 1"
                            value={this.state.newExamData.instructions[1]}
                            aria-describedby="addon-wrapping"
                            onChange={this.instructionDataChange.bind(
                              this,
                              "1"
                            )}
                          />
                        </div>
                        <div className="input-group flex-nowrap mb-4">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                            >
                              2
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instruction 1"
                            aria-label="Instruction 2"
                            value={this.state.newExamData.instructions[2]}
                            aria-describedby="addon-wrapping"
                            onChange={this.instructionDataChange.bind(
                              this,
                              "2"
                            )}
                          />
                        </div>
                        <div className="input-group flex-nowrap mb-4">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                            >
                              3
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instruction 3"
                            aria-label="Instruction 3"
                            value={this.state.newExamData.instructions[3]}
                            aria-describedby="addon-wrapping"
                            onChange={this.instructionDataChange.bind(
                              this,
                              "3"
                            )}
                          />
                        </div>
                        <div className="input-group flex-nowrap mb-4">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                            >
                              4
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instruction 4"
                            aria-label="Instruction 4"
                            value={this.state.newExamData.instructions[4]}
                            aria-describedby="addon-wrapping"
                            onChange={this.instructionDataChange.bind(
                              this,
                              "4"
                            )}
                          />
                        </div>
                        <div className="input-group flex-nowrap mb-4">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                            >
                              5
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Instruction 5"
                            aria-label="Instruction 5"
                            value={this.state.newExamData.instructions[5]}
                            aria-describedby="addon-wrapping"
                            onChange={this.instructionDataChange.bind(
                              this,
                              "5"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="form-group">
                      <label htmlFor="exampleFormControlFile1">
                        Select Image
                      </label>
                      <input
                        type="file"
                        onChange={this.setImage.bind(this)}
                        className="form-control-file"
                        id="exampleFormControlFile1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="form-group row  text-center">
                <div className="col-4 text-left">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-save"
                    onClick={() => {
                      this.setState({ currentTab: 2 });
                    }}
                  >
                    Previous Step
                  </button>
                </div>
                <div className="col-4 text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                  >
                    Preview
                  </button>
                </div>
                <div className="col-4 text-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.finalSubmit()}
                  >
                    Submit Exam Details
                  </button>
                </div>
              </div>
            </div>

            <Modal show={this.state.showHide}>
              <Modal.Header
                closeButton
                onClick={() => this.handleModalShowHide()}
              >
                <div className="row text-center">
                  <Modal.Title>Delete Questions</Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="row text-center">
                  <div className="col-12">
                    As the number of questions changed from{" "}
                    {this.state.changedQuestionsCount} to{" "}
                    {this.state.newExamData.number_of_questions}.<br />
                    {this.state.changedQuestionsCount -
                      this.state.newExamData.number_of_questions}{" "}
                    Extra questions, Mark for deletion!
                  </div>
                </div>
                <hr />
                <div className="row text-center">
                  <table className="table text-center table-background">
                    <thead>
                      <tr>
                        <th scope="col">Question No.</th>
                        <th scope="col">Question</th>
                        <th scope="col">Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.questionsList.map((Qlist) => {
                        return (
                          <tr key={Qlist.question_number}>
                            <td>{Qlist.question_number}</td>
                            <td>
                              {/* {Qlist.question} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: Qlist.question,
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                onClick={() =>
                                  this.addRemoveForDeletion(
                                    Qlist.question_number
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => this.restructureQuestionsAndSubmit()}
                >
                  Submit and Continue
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showHide3}>
              <Modal.Header
                closeButton
                onClick={() => this.handleModalShowHide3()}
              >
                <div className="row text-center">
                  <Modal.Title>
                    Questions Decreased Alert-Verify Questions
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="row text-center">
                  <div className="col-12">
                    As the number of questions changed from{" "}
                    {this.state.changedQuestionsCount} to{" "}
                    {this.state.newExamData.number_of_questions}.<br />
                    {this.state.changedQuestionsCount -
                      this.state.newExamData.number_of_questions}{" "}
                    Extra questions, Mark for deletion!
                  </div>
                </div>
                <hr />
                <div className="row text-center">
                  <div className="col-5">
                    <Button
                      variant="primary"
                      onClick={() => this.deleteLastQuestions()}
                    >
                      Delete Last{" "}
                      {this.state.changedQuestionsCount -
                        this.state.newExamData.number_of_questions}{" "}
                      from list
                    </Button>
                  </div>
                  <div className="col-2 "></div>
                  <div className="col-5">
                    <Button
                      variant="primary"
                      onClick={() => this.deleteManually()}
                    >
                      Delete Manually
                    </Button>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal show={this.state.showHide1}>
              <Modal.Header
                closeButton
                onClick={() => this.handleModalShowHide1()}
              >
                <h4>Validate token details to continue....</h4>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {/* <div className="input-group flex-nowrap mb-4">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="addon-wrapping">Email:</span>
                                </div>
                                <input type="text" className="form-control" placeholder="Email" aria-label="Email"
                                    aria-describedby="addon-wrapping" onChange={this.examDataChange.bind(this, "email")}
                                />
                            </div> */}
                  <div className="input-group flex-nowrap mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="addon-wrapping">
                        Token:
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Token"
                      aria-label="Token"
                      aria-describedby="addon-wrapping"
                      onChange={this.examDataChange.bind(this, "token")}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4 text-center">
                    <Button
                      variant="primary"
                      onClick={() => this.validateData()}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <div
              className="modal fade bd-example-modal-lg"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="card h-100 text-center">
                    <div className="modal-header text-center">
                      <h5 className="modal-title w-100">Exam Preview</h5>
                      <button
                        type="button"
                        className="close "
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="card-body">
                      {this.state.newExamData.exam_title ? (
                        <h5 className="card-title">
                          <p>{this.state.newExamData.exam_title} Preview</p>
                        </h5>
                      ) : (
                        <></>
                      )}
                      {this.state.newExamData.exam_desc ? (
                        <p className="lead-para">
                          {this.state.newExamData.exam_desc}
                        </p>
                      ) : (
                        <></>
                      )}

                      <div className=" container-fluid boxes  ">
                        <div className="row">
                          {this.state.newExamData.exam_style ? (
                            <div className="col-md-4 py-3">
                              <strong> Style: </strong>
                              <p className="d-inline text-capitalize">
                                {this.state.newExamData.exam_style}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {this.state.newExamData.exam_mode ? (
                            <div className="col-md-4 py-3">
                              <strong>Mode: </strong>{" "}
                              <p className="d-inline text-capitalize">
                                {this.state.newExamData.exam_mode}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {this.state.newExamData.exam_type ? (
                            <div className="col-md-4 py-3">
                              <strong>Type: </strong>{" "}
                              <p className="d-inline text-capitalize">
                                {this.state.newExamData.exam_type}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="row">
                          {this.state.newExamData.number_of_questions ? (
                            <div className="col-md-4 py-3">
                              <strong>Questions: </strong>
                              <p className="d-inline">
                                {this.state.newExamData.number_of_questions}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {this.state.newExamData.total_exam_score ? (
                            <div className="col-md-4 py-3">
                              <strong>Marks: </strong>
                              <p className="d-inline">
                                {this.state.newExamData.total_exam_score}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {this.state.newExamData.exam_duration ? (
                            <div className="col-md-4 py-3">
                              <strong> Duration: </strong>{" "}
                              <p className="d-inline">
                                {this.state.newExamData.exam_duration} Min
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {this.state.newExamData.exam_status ? (
                      <div className="card-footer text-muted">
                        <strong>Status: </strong>{" "}
                        <p className="d-inline text-capitalize">
                          {this.state.newExamData.exam_status}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>

                  {this.state.questionsList.map((question, i) => {
                    return (
                      <div className="card h-100 text-center" key={i + "qmap"}>
                        {/* <div className="card-header">
                           <div className="row">
                            <div className=" col-md-3 py-3">
                              <strong> Question No.: </strong>{" "}
                              <p className="d-inline">
                                {question.question_number}
                              </p>
                            </div>
                            {question.score ? (
                              <div className=" col-md-3 py-3">
                                <strong>Score: </strong>{" "}
                                <p className="d-inline">{question.score}</p>
                              </div>
                            ) : (
                              <></>
                            )}
                            {question.negative_score ? (
                              <div className=" col-md-3 py-3">
                                <strong>Negative Score:</strong>{" "}
                                <p className="d-inline">
                                  {question.negative_score}
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                            {question.question_domain ? (
                              <div className=" col-md-3 py-3">
                                <strong> Domain: </strong>{" "}
                                <p className="d-inline">
                                  {question.question_domain}
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div> */}
                        <div className="card-body qbody">
                          <blockquote className="blockquote mb-0">
                            Question No :{question.question_number}
                            <p
                              className="d-inline"
                              dangerouslySetInnerHTML={{
                                __html: question.question,
                              }}
                            />
                          </blockquote>
                          <div className="row">
                            <div className="col-md-6 py-3">
                              <strong>A: </strong>
                              <p
                                className="d-inline"
                                dangerouslySetInnerHTML={{
                                  __html: question.answer_choice.a,
                                }}
                              />
                              {/* <p>{question.answer_choice.a}</p> */}
                            </div>
                            <div className="col-md-6 py-3">
                              <strong>B: </strong>
                              <p
                                className="d-inline"
                                dangerouslySetInnerHTML={{
                                  __html: question.answer_choice.b,
                                }}
                              />
                              {/* <p>{question.answer_choice.b}</p> */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 py-3">
                              {question.answer_choice.c == null ? (
                                <></>
                              ) : (
                                <div>
                                  <strong>C: </strong>{" "}
                                  <p
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{
                                      __html: question.answer_choice.c,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 py-3">
                              {question.answer_choice.d == null ? (
                                <></>
                              ) : (
                                <div>
                                  <strong>D: </strong>
                                  <p
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{
                                      __html: question.answer_choice.d,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 py-3">
                              {question.answer_choice.e == null ? (
                                <></>
                              ) : (
                                <div>
                                  <strong>E: </strong>{" "}
                                  <p
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{
                                      __html: question.answer_choice.e,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 py-3">
                              {question.correct_answer == null ? (
                                <></>
                              ) : (
                                <div>
                                  <strong> Correct Answer: </strong>{" "}
                                  <p
                                    className="d-inline text-capitalize"
                                    dangerouslySetInnerHTML={{
                                      __html: question.correct_answer,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 py-auto">
                              {question.reason == null ? (
                                <></>
                              ) : (
                                <div>
                                  Explanation:{" "}
                                  <div>
                                    <p
                                      className="d-inline text-left"
                                      dangerouslySetInnerHTML={{
                                        __html: question.reason,
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    );
                  })}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
