import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import "../utils/popup.css";
import "./Style.css";
import { showSuccessToast, showErrorToast } from "./../../ToastProvider";
import $ from "jquery";

export default class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation_token: "",
      Redirect: false,
      user: {},
      user_id: "",
      channel: "",
      tokenError: "",
    };
  }

  componentDidMount() {
    let user = JSON.parse(sessionStorage.getItem("userData"));
    let search = new URLSearchParams(this.props.location.search);
    if (search) {
      let id = search.get("id");
      let channel = search.get("channel");
      let validation_token = search.get("token");
      if (id) {
        this.VerifyUserWithLink(id, channel, validation_token);
      } else {
        this.setState({ user });
        let user_id = user.user_id;
        this.setState({ user_id });
      }
    }
    this.notifyUser();
  }

  // Validation for 4-digit token
  validateToken = () => {
    const { validation_token } = this.state;
    const tokenPattern = /^\d{4}$/; // Regex for a 4-digit number
    return tokenPattern.test(validation_token);
  };

  // Function to handle Submit button click
  handleSubmit = () => {
    if (this.validateToken()) {
      this.VerifyUser();
    } else {
      this.setState({ tokenError: "Please enter a valid 4-digit token." });
    }
  };

  setValidationToken = (e) => {
    this.setState({ validation_token: e.target.value, tokenError: "" });
  };

  VerifyUserWithLink(id, channel, validation_token) {
    if (!id) {
      id = this.state.user_id;
    }

    const verificationData = {
      validation_token: validation_token,
      user_id: id,
      channel: channel,
    };

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/validations",
      data: verificationData,
    })
      .then((response) => {
        let res = response.data;
        if (res && res.message === "successfully verified") {
          this.getUser();
          showSuccessToast("Details Verified Successfully");
          $("#forgotpassword_modal").modal("hide");
        } else {
          showErrorToast(
            "Token expired, please login again and try with a new token"
          );
        }
      })
      .catch((error) => {
        showErrorToast(error.response.data.errors[0].message);
      });
  }

  VerifyUser = () => {
    let user_id =
      this.state.user_id ||
      new URLSearchParams(this.props.location.search).get("id");

    if (!user_id) {
      this.setState({ user_id });
    }

    if (
      !this.state.validation_token ||
      this.state.validation_token.length === 0
    ) {
      return; // Prevent form submission
    }

    const verificationData = {
      validation_token: this.state.validation_token,
      user_id: user_id,
      channel: "email",
    };

    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/validations",
      data: verificationData,
    })
      .then((response) => {
        let res = response.data;
        if (res && res.message === "successfully verified") {
          this.getUser();
          showSuccessToast("Verification successful!");
        } else {
          showErrorToast("Token expired, please verify with email again");
        }
      })
      .catch((error) => {
        showErrorToast(error.response.data.errors[0].message);
      });
  };

  getUser() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        let res = response.data;
        if (res && res.user_id && res.email && res.is_email_verified) {
          sessionStorage.setItem("userData", JSON.stringify(res));
          sessionStorage.setItem("userType", "student");
          sessionStorage.setItem("isInstructor", false);
          $("#forgotpassword_modal").modal("hide");

          showSuccessToast("Thanks for email verification!");

          this.setState({ Redirect: true }, () => {
            window.location.reload();
          });
        } else {
          console.log("User details not found, Please login again");
          showErrorToast("User details not found, please login again.");
        }
      })
      .catch((error) => {
        showErrorToast(error.response.data.errors[0].message);
      });
  }

  notifyUser() {
    let data = {
      channel: "email",
    };
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/tokens",
      data: data,
    })
      .then(() => {
        showSuccessToast(
          "Please check your inbox for an email containing the token. If you don't see it, be sure to check your spam folder as well."
        );
      })
      .catch((error) => {
        showErrorToast(error.response.data.errors[0].message);
      });
  }

  render() {
    if (this.state.Redirect === true) {
      return <Redirect to={{ pathname: "/studentlanding" }} />;
    }
    return (
      <div>
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div className="card text-center cardUser mt-4">
              <div className="card-header">
                Please check your inbox or spam folder. Your verification is in
                progress — you&apos;re almost there!
              </div>
              <div className="card-body">
                <div className="row mt-1 mb-1 userVerifyButtons">
                  <label
                    htmlFor="tokenInput"
                    className="mr-2" // Margin to the right for spacing
                    style={{ minWidth: "300px" }} // Ensures the label has enough space
                  >
                    Enter your 4-digit verification token:
                  </label>
                  <input
                    className="form-control-sm"
                    type="text"
                    id="tokenInput"
                    placeholder="****"
                    onChange={this.setValidationToken}
                    value={this.state.validation_token}
                    style={{ width: "100px", marginRight: "10px" }} // Adjust the width of the input and spacing
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={this.handleSubmit}
                  >
                    Verify Token
                  </button>
                </div>
                {this.state.tokenError && (
                  <div className="text-danger mt-2">
                    {this.state.tokenError}
                  </div>
                )}
              </div>
              <div className="card-footer text-muted"></div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    );
  }
}
