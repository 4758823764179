import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { showSuccessToast, showErrorToast } from "./../../ToastProvider";
import "./Style.css";
import bcrypt from "bcryptjs";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationData: {
        user_type: "student",
      },
      errors: {},
      passwordValidation: {
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
      },
      emailValid: false, // Track email validation
      showPassword: false, // Track password visibility
      Redirect: false,
      showPasswordCriteria: false, // Add this state variable
    };
  }

  loginChange(field, e) {
    let registrationData = this.state.registrationData;
    registrationData[field] = e.target.value;
    this.setState({ registrationData });

    // Trigger password validation as the user types
    if (field === "password") {
      this.validatePassword(e.target.value);
    }

    // Trigger email validation as the user types
    if (field === "email") {
      this.validateEmail(e.target.value);
    }
  }

  // Password validation based on various criteria
  validatePassword(password) {
    let passwordValidation = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
    };
    this.setState({ passwordValidation });
  }

  // Email validation
  validateEmail(email) {
    const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      email
    );
    this.setState({ emailValid });
  }

  // Toggle password visibility
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleValidation() {
    let registrationData = this.state.registrationData;
    let errors = {};
    let formIsValid = true;

    // Validate first name and last name
    if (
      !registrationData["first_name"] ||
      registrationData["first_name"].length < 2
    ) {
      formIsValid = false;
      errors["first_name"] = "Must be at least 2 characters in length.";
    }
    if (
      !registrationData["last_name"] ||
      registrationData["last_name"].length < 2
    ) {
      formIsValid = false;
      errors["last_name"] = "Must be at least 2 characters in length.";
    }

    // Email
    if (!registrationData["email"]) {
      formIsValid = false;
      errors["email"] = "Email cannot be empty.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        registrationData["email"]
      )
    ) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    } else {
      // Show the email validation success message
      this.setState({ emailValidationSuccess: true });
    }

    // Validate password (already handled via real-time validation)
    const { length, uppercase, lowercase, number } =
      this.state.passwordValidation;
    if (!length || !uppercase || !lowercase || !number) {
      formIsValid = false;
      errors["password"] = "Password must meet the criteria below.";
    }

    // Set errors if validation fails
    this.setState({ errors });
    return formIsValid;
  }

  onLoginFormSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation()) {
      // Hash the password before submission
      let registrationData = { ...this.state.registrationData };
      registrationData["password"] = bcrypt.hashSync(
        registrationData["password"],
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/users/signup",
        data: registrationData,
      })
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem("userData", JSON.stringify(response.data));
            this.setState({ Redirect: true });
            showSuccessToast("Registration successful!");
          }
        })
        .catch((error) => {
          showErrorToast(error.response.data.errors[0].message);
        });
    }
  };

  clearError = (field) => {
    let errors = { ...this.state.errors };
    errors[field] = "";
    this.setState({ errors });
  };

  render() {
    const { passwordValidation, emailValid, showPassword } = this.state;

    if (this.state.Redirect) {
      return <Redirect to="/onboardingdetails" />;
    }

    return (
      <div
        className="regi"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "10px" }}
      >
        <div className="container">
          <h5 className="signin mt-4">Sign Up</h5>
          <br></br>
          <form method="post" onSubmit={this.onLoginFormSubmit}>
            {/* First Name */}
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your First Name*"
                onChange={this.loginChange.bind(this, "first_name")}
                onFocus={() => this.clearError("first_name")}
                defaultValue={this.state.registrationData["first_name"] || ""}
              />
              <span style={{ color: "red" }}>
                {this.state.errors["first_name"]}
              </span>
            </div>

            {/* Last Name */}
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Last Name*"
                onChange={this.loginChange.bind(this, "last_name")}
                onFocus={() => this.clearError("last_name")}
                defaultValue={this.state.registrationData["last_name"] || ""}
              />
              <span style={{ color: "red" }}>
                {this.state.errors["last_name"]}
              </span>
            </div>

            {/* Email Field with validation */}
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email*"
                onChange={this.loginChange.bind(this, "email")}
                onFocus={() => this.clearError("email")}
                defaultValue={this.state.registrationData["email"] || ""}
              />
              <span style={{ color: emailValid ? "green" : "red" }}>
                {emailValid
                  ? "✔️ Email validation success"
                  : this.state.errors["email"]}
              </span>
            </div>

            {/* Password Field with validation */}
            <div className="form-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Create Password*"
                onChange={this.loginChange.bind(this, "password")}
                onFocus={() => {
                  this.clearError("password");
                  this.setState({ showPasswordCriteria: true }); // Show criteria on focus
                }}
              />
              <span style={{ color: "red" }}>
                {this.state.errors["password"]}
              </span>

              {/* Conditionally render the password requirements based on showPasswordCriteria */}
              {this.state.showPasswordCriteria && (
                <div className="password-requirements">
                  <p
                    style={{
                      color: passwordValidation.length ? "green" : "red",
                    }}
                  >
                    {passwordValidation.length ? "✔️" : "❌"} At least 8
                    characters
                  </p>
                  <p
                    style={{
                      color: passwordValidation.uppercase ? "green" : "red",
                    }}
                  >
                    {passwordValidation.uppercase ? "✔️" : "❌"} At least 1
                    uppercase letter
                  </p>
                  <p
                    style={{
                      color: passwordValidation.lowercase ? "green" : "red",
                    }}
                  >
                    {passwordValidation.lowercase ? "✔️" : "❌"} At least 1
                    lowercase letter
                  </p>
                  <p
                    style={{
                      color: passwordValidation.number ? "green" : "red",
                    }}
                  >
                    {passwordValidation.number ? "✔️" : "❌"} At least 1 number
                  </p>
                </div>
              )}
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn--wide btn-sm bg-green">
                Sign up
              </button>
              <div className="mt-2">
                <button
                  style={{
                    textDecoration: "none",
                    border: "none",
                    background: "none",
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.history.push("/signin");
                  }}
                >
                  Already have an account? Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUp);
