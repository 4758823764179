import React, { Component } from "react";
import "./Profile.css";
import axios from "axios";
import $ from "jquery";
import AvatarEditor from "react-avatar-editor";
import AppConstants from "../../../Constants/AppConstants";
import contrycodes from "./contrycodes.json";
import Sidebar from "../../Sidebar";
import bcrypt from "bcryptjs";
import { Fade } from "react-awesome-reveal";
// import Resizer from "react-image-file-resizer";

class StudentProfile extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false; // Add a mounted flag
    this.state = {
      catagoryDetails: {},
      primary_goal: {},
      primaryGoal: "",
      secondaryGoals: {},
      secondary_goals: [],
      categories: [],
      categoryList: [],
      userData: sessionStorage.getItem("userData"),
      showLoginL: false,
      profile: sessionStorage.getItem("userData"),
      avatar: "",
      image: "",
      errors: {},
      pCat: {},
      ccode: "+91",
      phone: "",
      contrycodes: contrycodes,
    };
    //this.getUser();
    this.getCatList();
  }

  componentDidMount() {
    this._isMounted = true; // Set the mounted flag when the component is mounted
    this.getUser();
  }

  componentWillUnmount() {
    this._isMounted = false; // Unset the flag when the component is unmounted
  }

  getUser() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        if (this._isMounted) {
          // Check if the component is still mounted
          this.setState({ userData: response.data });
          let user = response.data;
          this.setState({ profile: user });
          this.setState({ verified: user.is_email_verified });
          let cat = response.data.primary_goal;
          if (cat === "{no goal set}") {
            this.setState({ primaryGoal: "NA" });
          } else {
            this.getCategory(cat);
          }
          let secondary = response.data.secondary_goals;
          if (secondary[0] === "[{no goals set}]") {
            console.log("no goal set");
          } else {
            let cat = secondary[0];
            this.getCat(cat);
          }
          let avatar = AppConstants.IMG_URL + user.profile_image;
          this.setState({ avatar });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log(error.response.data.errors[0].message);
        }
      });
  }

  async getCategory(catagory_id) {
    const { data: response } = await axios
      .get("/api/catagories/" + catagory_id)
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    let cat = response;
    this.setState({ primaryGoal: response.catagory });
    let category = {
      catagory: cat.catagory,
      catagory_id: cat.catagory_id,
    };
    this.setState({ pCat: category });
  }

  async getCat(catagory_id) {
    const { data: response } = await axios
      .get("/api/catagories/" + catagory_id)
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    let cat = response;
    let category = {
      catagory: cat.catagory,
      catagory_id: cat.catagory_id,
    };
    this.setState({ secondaryGoals: category });
  }

  async getCatList() {
    const { data: response } = await axios
      .get("/api/catagories/?status=active")
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ categoryList: response.catagories });
    console.log(this.state.categoryList);
  }

  verify() {
    this.props.history.push("/verifyuser");
  }

  forgotPassword = () => {
    const forgotEmail = {
      email: this.state.email,
    };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/passwords/forgot",
      data: forgotEmail,
    })
      .then(() => {
        $("#forgotpassword_modal").modal("hide");
        this.setState({ showLoginL: true });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  };

  resetPassword = () => {
    const resetData = {
      email: this.state.email,
      //new_password: this.state.new_password,
      new_password: bcrypt.hashSync(
        this.state.new_password,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ), // hash created previously created upon sign up
      current_password: bcrypt.hashSync(
        this.state.validation_token,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ),
      //this.state.validation_token,
    };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/passwords/change",
      data: resetData,
    })
      .then(() => {
        this.setState({ showLoginL: false });
        console.log("Password Changed !");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  };

  setNewpassword = (e) => {
    this.setState({ new_password: e.target.value });
  };

  setValidationToken = (e) => {
    this.setState({ validation_token: e.target.value });
  };

  changePass() {
    $("#exampleModalChangePsw").modal("show");
  }

  async setImage(e) {
    try {
      const file = e.target.files[0];
      this.setState({ image: file });
      // await this.resizeFile(file);
    } catch (err) {
      console.log(err);
    }
  }

  // resizeFile(file) {
  //     new Promise((resolve) => {
  //         Resizer.imageFileResizer(
  //             file,
  //             300,
  //             300,
  //             "JPEG",
  //             100,
  //             0,
  //             (uri) => {
  //                 this.setState({ image: uri });
  //                 resolve(uri);
  //             },
  //             "base64"
  //         );
  //     });
  // }

  uploadImage() {
    let image = this.state.image;
    if (image) {
      var bodyFormData = new FormData();
      bodyFormData.append("profile", image);
      axios({
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        url: "/api/images/profile",
        data: bodyFormData,
      })
        .then(() => {
          console.log("image uploaded");
          this.getUser();
          window.location.reload();
        })
        .catch(() => {
          // console.log(error?.message);
          console.log("Image uploading failed, image size should be max 500kb");
        });
    } else {
      console.log("no image found");
    }
  }

  profileChange(field, e) {
    let profile = this.state.profile;
    profile[field] = e.target.value;
    this.setState({ profile });
  }

  setPrimaryGoals = (e) => {
    let profile = this.state.profile;
    profile["primary_goal"] = e.target.value;
    this.setState({ profile });
  };

  setContryCode = (e) => {
    this.setState({ ccode: e.target.value });
  };

  setPhoneNo = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ phone: e.target.value });
    }
  };

  setSecondaryGoals = (e) => {
    let profile = this.state.profile;
    profile["secondary_goals"] = e.target.value;
    this.setState({ profile });
  };

  editProfile() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        this.setState({ userData: response.data });
        let user = response.data;
        this.setState({ profile: user });
        if (
          !this.state.userData.phone ||
          this.state.userData.phone === "+000-0000000000"
        ) {
          let profile = this.state.profile;
          profile["phone"] = "";
          this.setState({ profile });
        } else {
          let str = this.state.userData.phone;
          const myArr = str.split("-");
          if (myArr && myArr.length > 1) {
            this.setState({ ccode: myArr[0] });
            this.setState({ phone: myArr[1] });
          } else if (myArr && myArr.length === 1) {
            this.setState({ phone: myArr[0] });
          }
        }
        $("#exampleModal").modal("show");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  editedProfilesubmit() {
    let profile = this.state.profile;
    profile.phone = this.state.ccode + "-" + this.state.phone;
    if (this.handleValidation()) {
      axios({
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        data: profile,
        url: "/api/users/profile",
      })
        .then(() => {
          $("#exampleModal").modal("hide");
          this.getUser();
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  handleValidation() {
    let registrationData = this.state.profile;
    let errors = {};
    let formIsValid = true;
    //first_name
    if (!registrationData["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    }

    if (typeof registrationData["first_name"] !== "undefined") {
      // Trim any spaces from the start and end of the string
      registrationData["first_name"] = registrationData["first_name"].trim();

      if (!registrationData["first_name"].match(/^[A-Za-z]+$/)) {
        formIsValid = false;
        errors["first_name"] = "Only letters are allowed";
      }
    }
    //last_name
    if (!registrationData["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    }
    if (typeof registrationData["last_name"] !== "undefined") {
      // Trim any spaces from the start and end of the string
      registrationData["last_name"] = registrationData["last_name"].trim();

      if (!registrationData["last_name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["last_name"] = "Only letters";
      }
    }

    if (typeof registrationData["email"] !== "undefined") {
      let lastAtPos = registrationData["email"].lastIndexOf("@");
      let lastDotPos = registrationData["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          registrationData["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          registrationData["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    //mobile
    if (!registrationData["phone"]) {
      formIsValid = false;
      errors["mobile"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  enrolledexams() {
    this.props.history.push("/enrolled-exams");
  }

  studentlanding() {
    this.props.history.push("/studentlanding");
  }

  studentSubscriptions() {
    this.props.history.push("/transactions");
  }

  render() {
    return (
      <div className="container">
        {this.state.showLoginL === false ? (
          <div className="row">
            <Sidebar />
            <div className="conatiner col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <div className="repo-cards-div-main">
                <div className=" col-lg-4 col-md-4 ">
                  <div className="repo-card-div card avatar ">
                    <Fade bottom duration={1000} distance="40px">
                      <img
                        className="profileimage"
                        src={this.state.avatar}
                        alt="Card cap"
                      />
                      <div className="card-body text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          Change Profile Image
                        </button>
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="card  col-lg-6 col-md-6">
                  <div className="repo-card-div">
                    <Fade bottom duration={2000} distance="40px">
                      <div className="row">
                        <div className="col">
                          <p className="mb-0 text-left">Name</p>
                        </div>
                        <div className="col text-secondary">
                          <p className="mb-0 text-left">
                            {this.state.userData.first_name}{" "}
                            {this.state.userData.last_name}
                          </p>
                        </div>
                      </div>
                      <hr className="col hrprofile" />
                      <div className="row">
                        <div className="col">
                          <p className="mb-0 text-left">Email</p>
                        </div>
                        <div className="col text-secondary">
                          <p className="mb-0 text-left">
                            {this.state.userData.email}
                          </p>
                        </div>
                      </div>
                      <hr className="col hrprofile" />
                      <div className="row">
                        <div className="col">
                          <p className="mb-0 text-left">Phone</p>
                        </div>
                        <div className="col text-secondary">
                          {!this.state.userData.phone ||
                          this.state.userData.phone === "+000-0000000000" ? (
                            <span></span>
                          ) : (
                            <span>
                              {" "}
                              <p className="mb-0 text-left">
                                {this.state.userData.phone}
                              </p>
                            </span>
                          )}
                        </div>
                      </div>
                      <hr className="col hrprofile" />
                      <div className="row">
                        <div className="col">
                          <p className="mb-0 text-left">Gender</p>
                        </div>

                        {this.state.userData.gender === 1 ? (
                          <div className="col text-secondary">
                            <p className="mb-0 text-left">Male</p>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.userData.gender === 2 ? (
                          <div className="col text-secondary">
                            <p className="mb-0 text-left">Female</p>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.userData.gender === 3 ? (
                          <div className="col text-secondary">
                            <p className="mb-0 text-left">Other</p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <hr className="col hrprofile" />
                      <div className="row">
                        <div className="col">
                          <p className="mb-0 text-left">Primary Goal</p>
                        </div>
                        <div className="col text-secondary">
                          <p className="mb-0 text-left">
                            {this.state.primaryGoal}
                          </p>
                        </div>
                      </div>
                      <hr className="col hrprofile" />
                      <div className="row">
                        <div className="col">
                          <p className="mb-0 text-left">Secondary Goals</p>
                        </div>
                        <div className="col text-secondary">
                          <p className="mb-0 text-left">
                            {this.state.secondaryGoals.catagory}
                          </p>
                        </div>
                      </div>
                      <hr className="col hr" />
                    </Fade>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary  btn-sm mr-1 m-1"
                      onClick={() => this.editProfile()}
                      data-whatever="@getbootstrap"
                    >
                      Edit Profile
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary  btn-sm m-1"
                      onClick={() => this.changePass()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        ) : (
          // </div>
          <></>
        )}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Profile
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          First Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="recipient-name"
                          defaultValue={this.state.userData["first_name"] || ""}
                          onChange={this.profileChange.bind(this, "first_name")}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["first_name"]}
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-lname"
                          className="col-form-label "
                        >
                          Last Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="recipient-lname"
                          defaultValue={this.state.userData["last_name"] || ""}
                          onChange={this.profileChange.bind(this, "last_name")}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["last_name"]}
                      </span>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-12 mt-3">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-email"
                          className="col-form-label"
                        >
                          Email:
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          disabled
                          id="recipient-email"
                          defaultValue={this.state.userData["email"] || ""}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["email"]}
                      </span>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-2 mt-3 ccode">
                      <div className="form-group">
                        <label htmlFor="recipient-ccode">Contry Code</label>
                        <select
                          className="form-control "
                          id="recipient-ccode"
                          name="ccode"
                          onChange={this.setContryCode}
                        >
                          <option value={this.state.ccode}>
                            {" "}
                            {this.state.ccode}
                          </option>
                          {this.state.contrycodes.countries.map((item, i) => {
                            return (
                              <option key={i} value={item.code}>
                                {item.code}-{item.name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-4 mt-3">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-phone"
                          className="col-form-label"
                        >
                          Phone:
                        </label>
                        <input
                          type="text"
                          maxLength="12"
                          className="form-control"
                          id="recipient-phone"
                          value={this.state.phone}
                          // onChange={this.profileChange.bind(this, "phone")}
                          onChange={this.setPhoneNo}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["mobile"]}
                      </span>
                    </div>
                    <div className="col-6 mt-3">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-email"
                          className="col-form-label"
                        >
                          Email:
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          disabled
                          id="recipient-email"
                          defaultValue={this.state.userData["email"] || ""}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["email"]}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="primarygoals">Primary Goal</label>
                        <select
                          className="form-control "
                          id="primarygoals"
                          name="primarygoals"
                          onChange={this.setPrimaryGoals}
                        >
                          <option value={this.state.pCat.catagory_id}>
                            {" "}
                            {this.state.pCat.catagory}
                          </option>
                          {this.state.categoryList.map((item, i) => {
                            return (
                              <option key={i} value={item.catagory_id}>
                                {" "}
                                {item.catagory}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="secondarygoals">Secondary Goals</label>
                        <select
                          className="form-control "
                          id="secondarygoals"
                          name="secondarygoals"
                          required
                          type="text"
                          onChange={this.setSecondaryGoals}
                        >
                          <option value={this.state.secondaryGoals.catagory_id}>
                            {" "}
                            {this.state.secondaryGoals.catagory}
                          </option>
                          {this.state.categoryList.map((item, i) => {
                            return (
                              <option key={i} value={item.catagory_id}>
                                {" "}
                                {item.catagory}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary  btn-sm"
                  onClick={() => {
                    this.editedProfilesubmit();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Upload Image
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlFile1">
                      Select image to import
                    </label>
                    <input
                      type="file"
                      onChange={this.setImage.bind(this)}
                      className="form-control-file"
                      id="exampleFormControlFile1"
                    />
                  </div>
                </form>
                <div className="mt-1 mb-1">
                  <AvatarEditor
                    image={this.state.image}
                    width={250}
                    height={250}
                    border={50}
                    color={[255, 255, 255, 0.6]}
                    scale={1.2}
                    rotate={0}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.uploadImage();
                  }}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModalChangePsw"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content" style={{ width: "60%" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Change Password
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="currentPassword">Current Password</label>
                    <input
                      className="form-control"
                      id="currentPassword"
                      type="password"
                      placeholder="Enter Current Password"
                      onChange={this.setValidationToken}
                      value={this.state.validation_token}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      className="form-control"
                      id="newPassword"
                      type="password"
                      placeholder="Enter New Password"
                      onChange={this.setNewpassword}
                      value={this.state.new_password}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary  btn-sm"
                  data-dismiss="modal"
                  onClick={() => {
                    this.resetPassword();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudentProfile;
