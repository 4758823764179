import axios from "axios";
import React, { Component } from "react";
import "./Style.css";
import bcrypt from "bcryptjs";
import { toast } from "react-toastify";

class ForgotPassword extends Component {
  state = {
    email: "",
    new_password: "",
    validation_token: "",
    errors: {},
  };

  componentDidMount() {
    const search = new URLSearchParams(this.props.location.search);
    const email = search.get("email");
    if (email) {
      this.setState({ email });
    }
  }

  validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()-+]).{6,}$/;
    return passwordPattern.test(password);
  };

  resetPassword = () => {
    const { new_password, validation_token } = this.state;
    const errors = {};

    if (!new_password) {
      errors.new_password = "Password cannot be empty";
    } else if (!this.validatePassword(new_password)) {
      errors.new_password =
        "Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    }

    if (!validation_token) {
      errors.validation_token = "Token cannot be empty";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const hashedPassword = bcrypt.hashSync(
      new_password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );

    const resetData = {
      email: this.state.email,
      new_password: hashedPassword,
      validation_token: validation_token,
    };

    axios
      .post("/api/users/passwords/reset", resetData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        toast.success("Password Reset Successful!. Please login again");
        this.props.history.push("/signin");
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
        if (
          error.response.data.errors[0].message !==
          "Invalid token. Please retry."
        ) {
          this.props.history.push("/forgotpassword-emailentry");
        }
      });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: { ...this.state.errors, [e.target.name]: null },
    });
  };

  render() {
    const { new_password, validation_token, errors } = this.state;

    return (
      <div
        className="regi"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "10px" }}
      >
        <div className="container ">
          <h5 className="signin mt-4">Set New Password</h5>
          <div className="form">
            <div className="mt-4 p-4">
              <div>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Enter New Password*"
                  name="new_password"
                  value={new_password}
                  onChange={this.handleInputChange}
                />
                {errors.new_password && (
                  <div className="text-danger">{errors.new_password}</div>
                )}
                <br />
                <input
                  className="form-control"
                  type="password"
                  placeholder="Enter Token*"
                  name="validation_token"
                  value={validation_token}
                  onChange={this.handleInputChange}
                />
                {errors.validation_token && (
                  <div className="text-danger">{errors.validation_token}</div>
                )}
                <div className="modal-footer">
                  <br />
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6 text-center">
                      <button
                        type="button"
                        className="btn btn--wide bg-green"
                        onClick={this.resetPassword}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
